<div
  data-bs-toggle="collapse"
  [attr.data-bs-target]="'#collapse' + id"
  [attr.aria-expanded]="isCollapseOpen ? 'true' : 'false'"
  [attr.aria-controls]="'#collapse' + id"
  class="card-title mb-3"
  (click)="isCollapseOpen = !isCollapseOpen">
  <div class="d-flex w-100 align-items-center justify-content-between">
    <span class="title">{{ title2 | uppercase }} {{ title | uppercase }}</span>
    <fa-icon [icon]="isCollapseOpen ? faChevronUp : faChevronDown"></fa-icon>
  </div>
</div>
<div class="collapse {{ isCollapseOpen ? 'show' : '' }}" [attr.id]="'collapse' + id" [attr.data-bs-parent]="collapseParentDivId">
  <!-- having a div wrapping the content with the padding avoids animation jerkyness -->
  <div class="{{ noPadding ? '' : 'p-4' }}" style="box-sizing: border-box !important;">
    <ng-content />
  </div>
</div>
