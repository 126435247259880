import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appCandidaturaEstado',
})
export class CandidaturaEstadoPipe implements PipeTransform {
  transform(value: number): string {
    const estadosBolsa = ['Proposta para apoio', 'Não proposta para apoio'];

    return estadosBolsa[value - 1];
  }
}
