import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Nutsll'
})
export class NutsllPipe implements PipeTransform {

  transform(value: string): string {
    const nuts2 = [
      {
        id: '11',
        label: "Norte"
      },
      {
        id: '15',
        label: "Algarve"
      },
      {
        id: '16',
        label: "Centro"
      },
      {
        id: '17',
        label: "Grande Lisboa"
      },
      {
        id: '18',
        label: "Alentejo"
      },
      {
        id: '20',
        label: "R. A. Açores"
      },
      {
        id: '30',
        label: "R. A. Madeira"
      },
      {
        id: '40',
        label: "Oeste e Vale do Tejo"
      },
      {
        id: '50',
        label: "Península de Setúbal"
      }
    ]
    
    let [selectedNuts2] = nuts2.filter(i => i.id === value);

    return selectedNuts2.label;
  }

}
