import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[nif]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NifDirective,
      multi: true,
    },
  ],
})
export class NifDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }
    var isValid = this.isNifValid(control.value);
    control.setErrors(isValid ? null : { 'nif': true });
    return isValid ? null : { nif: true };
  }

  //https://pt.wikipedia.org/wiki/N%C3%BAmero_de_identifica%C3%A7%C3%A3o_fiscal#Exemplo_de_valida%C3%A7%C3%A3o_em_TypeScript
  isNifValid(nif: string) {
    const validationSets = {
      one: ['1', '2', '3', '5', '6', '8'],
      two: [
        '45',
        '70',
        '71',
        '72',
        '74',
        '75',
        '77',
        '79',
        '90',
        '91',
        '98',
        '99',
      ],
    };
    if (nif.length !== 9) return false;
    if (
      !validationSets.one.includes(nif.substring(0, 1)) &&
      !validationSets.two.includes(nif.substring(0, 2))
    )
      return false;
    const nifNumbers = nif.split('').map((c) => Number.parseInt(c));
    const total =
      nifNumbers[0] * 9 +
      nifNumbers[1] * 8 +
      nifNumbers[2] * 7 +
      nifNumbers[3] * 6 +
      nifNumbers[4] * 5 +
      nifNumbers[5] * 4 +
      nifNumbers[6] * 3 +
      nifNumbers[7] * 2;
    const modulo11 = Number(total) % 11;
    const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;
    return checkDigit === Number(nif[8]);
  }
}
