import { ILinks } from 'src/app/shared/interfaces/link.interface';

export const coletivolLinks: Array<ILinks> = [
  {
    path: '/balcao-artes',
    title: 'Balcão artes',
    tooltip: 'Disponível brevemente',
    greyedOutBoolean: true,
  },
  {
    path: '/e-registo/identificacao-da-entidade',
    title: 'E-registo',
  },
  // {
  //   path: '/balcao-artes/apoio-as-artes/rtcp',
  //   title: 'RTCP',
  // },
  // {
  //   path: '/balcao-artes/apoio-as-artes/rpac',
  //   title: 'RPAC',
  // },
  {
    path: '/programas-de-apoio/concursos-a-decorrer',
    title: 'Programas de apoio',
  },
  {
    path: '/rtcp',
    title: 'RTCP',
    // tooltip: 'Disponível brevemente',
    // greyedOutBoolean: true
  },
  {
    path: '/rpac',
    title: 'RPAC',
    // tooltip: 'Disponível brevemente',
    // greyedOutBoolean: true
  },
  {
    path: '',
    title: 'Consultas de dados',
    tooltip: 'Disponível brevemente',
    greyedOutBoolean: true,
  },
  {
    path: '',
    title: 'Serviços de Apoio',
    tooltip: 'Disponível brevemente',
    greyedOutBoolean: true,
  },
];
