import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estadoRecibo',
})
export class reciboPipe implements PipeTransform {
  transform(value: number): string {
    const estadoRecibo = ['VALIDADO', 'NÃO VALIDADO'];

    return estadoRecibo[value];
  }
}
