import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TipoProgramaDeApoio'
})
export class TipoProgramaDeApoioPipe implements PipeTransform {
  
  
  transform(value: number): string {
    const tipoEquipaRH = [
      'Sustentado',
      'Projetos',
      'Parceria',
      'Extraordinário',
      'Outro'
    ]
    
    return tipoEquipaRH[value - 1] || '';
  }

}