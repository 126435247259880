<ul>
  <li class="mb-4" *ngFor="let item of list">
    <div class="d-flex align-items-start">
      <div class="avatar-wrapper">
        <div
          class="avatar me-2"
          [ngStyle]="{
            backgroundImage:
              'url(' +
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPYAAADNCAMAAAC8cX2UAAAAMFBMVEX////NzMzQz8/x8PDKycn7+/vT0tL5+fn09PTS0dHq6urX1tbu7u7a2dne3d3i4uJvH3pBAAAFGklEQVR4nO2d25qrIAyFBwVREXj/t91ap9N22ukuEA5L81/10vXRhEWA8PXFMAzDMAzDMAzDMAzDoKGU0v2y9Hr9UftbiqDHxTszyR8m4/wy6trflRM9e9tJKX4hZWf9fFTlizPiSfKPdGHcUvsLMzBPfym+Mc21v5IW7f8e58cx98f5r6vZfCT6ItzMB8nto/tY9EW4G2t/MQWfBPUjRwhxGzTU3wNua391IvrzqH7QbaAzW29iRG+Yvva3x9MHh/WNCVa36uJVC9GBTmRpqlF1K5umWgiLqNunqhbC19YQzpKuWnRwazI9EMgWA9r0HebD/0K62jrC6ClEb2DN3slZ/AqUOycbbKzhJhtsqOFO8eK/AfLmM51qIWBqDum29B4YizoS/sdXy4JSWltIrMoVieJQPa1skAWJcpSqhXAYwa1IViE3BhDZpBltnbkxZGvS0F6DG2P1ybJZ9vFl06oWAkP2WSewk8qO3u97jQGRfU5z+kUtu7aeDznnCuyssk9aZjip7DFxP/+RDqWWNpL6FZgSoib1KzBntWj9CopbofUrQFvclBM3yrStvKNNaQ5BOO0G2A7ANhjBuaxnmh9v2jn7SvNzN+nW9o3GN7mpSwxXGp+8abe2b0xt/8t74hr5Fdn2CZY5l+y2g5u2sHInu+0pLFNGa72OeFLZxDsDN0xtZW/J4tE2htrK3nJS2RmWXzttx/ZJJ7CTujSamzHPtH5XJs+/vPH/+GnLDCctKpEf4dhAOMZBn8wbT+PfUNzufABkp3chTWs47QpG7yxJUW2yDqrFlNIk7txqgGT2AIVvad6lPENRTGy8cPgKknwOksPv6AnS+QCTxH+gOMACc2zljvQiKtD5jRsEe594GW0N7uRDeUBXt+9IDu62q6V/kRzciKG9OvNEwyKbL6m8JnE1MtX+/kjS/CmgM93RaW3iAL3KTsoqDNKr7KScp4c5Pf+ChOox3lL7RvxmQfsbAu+INuaoafybmF6+B+jmG3e9F+QS7xtiwhs7sHfm4FmsAw/sC+Hr7g5ynf0Lls2yWTbLZtnosGyWzbJZNstGh2WzbJZ9UNnhz6AdoKYUs0WAvDWwE1koh66m6XmI3PyTA9QR23tGn3R6xXjEEE8UjSl8dCQ90zqkNz3VYp+fXY5DSrtgbAyNM3V717n9Ie993Mud75DGt+3bFporIs9Mttmj5Sr8Nd4g5S0+1azW3J3pEvMVueb1tpSPc3bR38IbSm+bMykg+iK8GQ+zGNJurv+nM7XTmxp9qXG+RwpfMcrH2dYQvQu3daJcLT52VUmkfPDFfavypSP6FZ3xJYWP2brKhGML/dfjSyZ5KFGIWSN6akr0hpzyRrmabQkzFkFn8zl2n60/FgVDljKzdrXm6E+RwlEHeR03Fsrm3ghF68rO5HNWD0M24tTFsbwYmh2VEUr0hiH4p2dqQZ2X1AHXDiSoH5FpOb0l8x1GilXP0/OtDPH3TahfnS1L9Bu3cXe4WiH2Lhnt+1bliXtRK0d3v7JE9RJcGqgapdHFDHeWFpZliViK5ngEpzQRj+4gz9lXIubuvvY3UxB+GiDXUxkliehhkqvTeEnCeynmevynLMFPDdG+zliLYMOSq796WYK7uesDhPYa3KGyU7t+tUFw7zH05ddO8CLsCNN2xMR9hGk7YuI+xLQd3n0Ms1D8m2DZB1h2boTW0w5h0sKfEWPZyITKhq8f7oT2Sj1EIl9TOctm2SybZR9f9j/VK1+I3TaZtAAAAABJRU5ErkJggg==' +
              ')'
          }"
        ></div>
      </div>
      <div class="d-flex flex-column">
        <span
          ><b>Usuário</b> atualizou <b> Descrição</b>&nbsp;&nbsp;&nbsp;20 de
          fevereiro de 2023 às 18:40</span
        >
        <div class="d-flex align-items-center mt-2">
          <span class="description" [ngClass]="{'w-100': item.type === 'description'}">
            {{ item.description }}
          </span>
          <div class="mx-2">&#8594;</div>
          <span class="description" [ngClass]="{'w-100': item.type === 'description'}">This issue relates to SGIAR-155</span>
        </div>
      </div>
    </div>
  </li>
</ul>
