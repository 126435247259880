<form class="mt-3"  (ngSubmit)="context.submit()" #formDirective="ngForm">

    <ng-content></ng-content>


    <div class="row" *ngIf="context.debug" >
        <div class="col-xl-12">
            {{ context.loading | json }}
            {{ context.form.pristine | json }} 
            {{ context.form.valid | json }}
            <pre>
                {{context.form.value | json}}
            </pre>
        </div>
    </div>

    <div class="row mt-3" *ngIf="!context.formIsReadOnly">
        <div class="col-12">
            <div class="w-100 d-flex justify-content-end d-none d-lg-flex">
                <button [disabled]="(context.loading || context.form.pristine) && !context.keepCancelButtonActive" mat-stroked-button type="button" color="primary" class="me-2" (click)="context.cancel()">
                    CANCELAR
                </button>
                <button  [disabled]="context.loading || context.form.pristine || !context.form.valid" mat-flat-button type="submit"
                    color="primary">
                    {{ context.loading ? "A PROCESSAR..." : context.saveButtonTitle || "GRAVAR" }}
                    <span class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"
                        *ngIf="context.loading"></span>
                </button>
            </div>
            <div class="d-lg-none">
                <button [disabled]="(context.loading || context.form.pristine) && !context.keepCancelButtonActive" mat-stroked-button color="primary" type="button" class="mb-2 w-100" (click)="context.cancel()">
                    CANCELAR
                </button>
                <button 
                    [disabled]="context.loading || context.form.pristine || !context.form.valid" mat-flat-button type="submit"
                    color="primary" class="w-100">
                    {{ context.loading ? "A PROCESSAR..." : context.saveButtonTitle || "GRAVAR" }}
                    <span class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"
                        *ngIf="context.loading"></span>
                </button>
            </div>
        </div>
    </div>
</form>