import { Dialog } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { ConcursoService } from 'src/app/shared/services/concurso.service';

@Component({
  selector: 'app-fases-concurso-header',
  templateUrl: './fases-concurso-header.component.html',
  styleUrls: ['./fases-concurso-header.component.scss'],
})

export class FasesConcursoHeader {
  public dataInicio: Date | null = null;
  public dataFim: Date | null = null;
  public responsaveis: any[] = new Array();

  constructor(
    private activatedRoute : ActivatedRoute,
    private concursoService: ConcursoService,
    private dialog: Dialog,
    private router: Router,
  ){
    let id = this.activatedRoute.parent?.snapshot.paramMap.get('id');

    if (id && +id) {
      this.concursoService.getConcursos(+id).pipe(take(1)).subscribe({
        next: (response) => {
          let concurso;
          let planificacao;
          if(! (concurso = response.data?.pop() )){
            return
          }
    
          if( ! ( planificacao =  concurso.concursoPlanificacoes?.filter((item:any) => item.tipoPlanificacao == 1).shift()) ){
            return
          }
    
          this.dataInicio = (planificacao.dataFim && planificacao.dataFim !== '0001-01-01T00:00:00') ? new Date(planificacao.dataFim) : null;
          this.dataFim = (planificacao.dataFim && planificacao.dataFim !== '0001-01-01T00:00:00') ? new Date(planificacao.dataFim) : null;
          this.responsaveis = planificacao.internalUser;
        }, error: error => {
          console.log(error);
          this.dialog.open(DialogComponent, {
            data: {
              type: 'custom-error',
              message: ['Ocorreu um erro ao obter os dados do responsável e datas estimadas. Tente novamente mais tarde.'],
            },
          });
          this.router.navigate(['interno/programas-de-apoio']);
        }
      })
    } else {
      this.dialog.open(DialogComponent, {
        data: {
          type: 'custom-error',
          message: ['ID de concurso inválido ou não encontrado. Tente novamene mais tarde.'],
        },
      });
      this.router.navigate(['interno/programas-de-apoio']);
    }
  }
}