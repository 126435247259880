import { VerificacaoCandidaturaGetArray } from './../interfaces/verificacao-candidatura.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseDefault } from 'src/app/shared/interfaces/response-default.interface';
import { IEstadoFormalizacaoPagamento } from '../interfaces/estado-formalizacao-pagamento.interface';
import { IFormalizacaoPagamento } from '../interfaces/formalizacao-pagamento.interface';

@Injectable({
  providedIn: 'root',
})
export class ConcursoService {
  private token = localStorage.getItem('userToken');
  private header = {
    headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
  };

  constructor(private http: HttpClient) {}

  private baseUrl = `${environment.apiUrl}/concurso`;

  getConcursos(id?: number): Observable<ResponseDefault<Array<any>>> {
    const url = id ? `${this.baseUrl}?Id=${id}` : `${this.baseUrl}`;
    return this.http.get<ResponseDefault<Array<any>>>(url, this.header);
  }
  getConcursosByPid(pid: number) {
    const url = `${this.baseUrl}/programa-apoio/${pid}`;
    return this.http.get<ResponseDefault<Array<any>>>(url, this.header);
  }

  postConcurso(body: any) {
    const url = `${this.baseUrl}`;
    const newBody = {
      nome: body.nome,
      areaArtisticaId: body.areasArtisticas,
      areaArtisticaComplementarId: body.areasArtisticasComplementares,
      meses: body.meses,
      dataInicio: body.dataInicio,
      dataFim: body.dataFim,
      address: {
        tipoTerritorio: body.ambitoTerritorial ? body.ambitoTerritorial : null,
        nuts2Id: body.nuts2 ? body.nuts2 : null,
        concelhoId: body.concelho ? body.concelho : null,
        freguesiaId: body.freguesia ? body.freguesia : null,
        continenteId: body.continentes ? body.continentes : null,
        regiaoId: body.regioes ? body.regioes : null,
        paisId: body.estadosTerritorios ? body.estadosTerritorios : null,
      },
      formaAtribuicao: body.formaAtribuicao,
      dominioArtisticoIds: body.dominiosArtisticosAtividade,
      subdominioArtisticoIds: body.subDominiosArtisticosAtividade,
      programaApoioId: body.programaApoioId,
    };
    return this.http.post<any>(url, newBody, this.header);
  }

  putConcursos(id: number, body: any) {
    const url = `${this.baseUrl}/${id}`;
    const newBody = {
      nome: body.nome,
      areaArtisticaId: body.areasArtisticas,
      areaArtisticaComplementarId: body.areasArtisticasComplementares,
      meses: body.meses,
      dataInicio: body.dataInicio,
      dataFim: body.dataFim,
      formaAtribuicao: body.formaAtribuicao,
      address: {
        tipoTerritorio: body.ambitoTerritorial ? body.ambitoTerritorial : null,
        nuts2Id: body.nuts2 ? body.nuts2 : null,
        concelhoId: body.concelho ? body.concelho : null,
        freguesiaId: body.freguesia ? body.freguesia : null,
        continenteId: body.continentes ? body.continentes : null,
        regiaoId: body.regioes ? body.regioes : null,
        paisId: body.estadosTerritorios ? body.estadosTerritorios : null,
      },
      dominioArtisticoIds: body.dominiosArtisticosAtividade,
      subdominioArtisticoIds: body.subDominiosArtisticosAtividade,
    };

    return this.http.put(url, newBody, this.header);
  }

  deleteConcursos(id: number) {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete(url, this.header);
  }

  putConcursoObjetivo(id: number, objetivoIds: Array<number>) {
    const url = `${this.baseUrl}/${id}/objetivo`;
    return this.http.put(url, { objetivoIds }, this.header);
  }

  getAcessos(id: string | null | undefined) {
    const url = `${this.baseUrl}/acesso/${id}`;
    return this.http.get<ResponseDefault<Array<any>>>(url, this.header);
  }

  saveAcessos(data: any) {
    return this.http.post<ResponseDefault<any>>(`${this.baseUrl}/acesso`, data, this.header);
  }

  searchUser(type: number, search: string, NumeroSgi: any) {
    let typeParam = type ? `&TipoUtilizador=${type}` : '';
    return this.http.get<ResponseDefault<Array<any>>>(`${environment.apiUrl}/user/utilizadores/sgi/${NumeroSgi}`, this.header);
  }

  postPlanificacao(concursoId: number, concursoPlanificacoes: any) {
    const url = `${this.baseUrl}/planificacao`;
    const newBody = {
      concursoId,
      concursoPlanificacoes,
    };
    return this.http.post<any>(url, newBody, this.header);
  }

  putPlanificacao(concursoPlanificacoes: any) {
    const url = `${this.baseUrl}/planificacao`;
    const newBody = {
      planificacoes: concursoPlanificacoes,
    };
    return this.http.put<any>(url, newBody, this.header);
  }

  getDotacao(id: string | null | undefined) {
    const url = `${this.baseUrl}/dotacaofinanceira/${id}`;
    return this.http.get<ResponseDefault<Array<any>>>(url, this.header);
  }

  saveDotacao(data: any, isNew: boolean) {
    const formData: FormData = new FormData();

    if (data.urlDocumento) {
      const blob = new Blob([data.urlDocumento], { type: 'multipart/form-data' });
      formData.append('FormFile', blob, data.urlDocumento.name);
    }

    formData.append('concursoId', data.concursoId);
    formData.append('dotacaoFinanceiraTotal', data.dotacaoFinanceiraTotal);
    formData.append('dotacaoFinanceiraModalidadeBienal', data.dotacaoFinanceiraModalidadeBienal);
    formData.append('dotacaoFinanceiraModalidadeQuadrienal', data.dotacaoFinanceiraModalidadeQuadrienal);
    formData.append('permitidaCumulacaoApoios', data.permitidaCumulacaoApoios);
    formData.append('montateAnualMazimoPermitido', data.montateAnualMazimoPermitido);
    formData.append('candidatoAcumulaPresencialmenteOutroApoio', data.candidatoAcumulaPresencialmenteOutroApoio);

    if (isNew) {
      return this.http.post<ResponseDefault<any>>(`${this.baseUrl}/dotacaofinanceira`, formData, this.header);
    }

    return this.http.put<ResponseDefault<any>>(`${this.baseUrl}/dotacaofinanceira/${data.id}`, formData, this.header);
  }

  getPatamar(id: string | null | undefined) {
    const url = `${this.baseUrl}/patamares-financiamento/${id}`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getPatamarList(id: string | null | undefined) {
    const url = `${this.baseUrl}/patamares-financiamento?concursoId=${id}`;
    return this.http.get<ResponseDefault<Array<any>>>(url, this.header);
  }

  savePatamar(data: any, isNew: boolean) {
    if (isNew) {
      return this.http.post<ResponseDefault<any>>(`${this.baseUrl}/patamares-financiamento`, data, this.header);
    }

    return this.http.put<ResponseDefault<any>>(`${this.baseUrl}/patamares-financiamento/${data.id}`, data, this.header);
  }

  deletePatamar(id: string) {
    const url = `${this.baseUrl}/patamares-financiamento/${id}`;
    return this.http.delete(url, this.header);
  }

  getApoio(id: string | null | undefined) {
    const url = `${this.baseUrl}/atribuicao-apoio/${id}`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getApoioList(id: string | null | undefined) {
    const url = `${this.baseUrl}/atribuicao-apoio?concursoId=${id}`;
    return this.http.get<ResponseDefault<Array<any>>>(url, this.header);
  }

  saveApoio(data: any, isNew: boolean) {
    if (isNew) {
      return this.http.post<ResponseDefault<any>>(`${this.baseUrl}/atribuicao-apoio`, data, this.header);
    }

    return this.http.put<ResponseDefault<any>>(`${this.baseUrl}/atribuicao-apoio/${data.id}`, data, this.header);
  }

  deleteApoio(id: string) {
    const url = `${this.baseUrl}/atribuicao-apoio/${id}`;
    return this.http.delete(url, this.header);
  }

  getAvisoAbertura(avisoAberturaId?: number, concursoId?: number): Observable<ResponseDefault<Array<any>>> {
    const avisoAberturaIdQuery = avisoAberturaId ? `AvisoAberturaId=${avisoAberturaId}` : '';
    const concursoIdQuery = concursoId ? `ConcursoId=${concursoId}` : '';
    const andQuery = avisoAberturaId && concursoId ? '&' : '';

    const queries = `${avisoAberturaIdQuery}${andQuery}${concursoIdQuery}`;

    const query = avisoAberturaId || concursoId ? '?' + queries : '';
    const url = `${this.baseUrl}/aviso-abertura${query}`;

    return this.http.get<ResponseDefault<Array<any>>>(url, this.header);
  }

  postAvisoAbertura(concursoId: number, formaApresentacao?: string, prazoApresentacao?: string, resumoAvisoAbertura?: string) {
    const url = `${this.baseUrl}/aviso-abertura`;
    const newBody = {
      concursoId: concursoId,
      formaApresentacao: formaApresentacao ? formaApresentacao : null,
      prazoApresentacao: prazoApresentacao ? prazoApresentacao : null,
      resumoAvisoAbertura: resumoAvisoAbertura ? resumoAvisoAbertura : null,
    };
    return this.http.post<any>(url, newBody, this.header);
  }

  putAvisoAbertura(id: number, formaApresentacao?: string, prazoApresentacao?: string, resumoAvisoAbertura?: string) {
    const url = `${this.baseUrl}/aviso-abertura/${id}`;
    const newBody = {
      formaApresentacao: formaApresentacao ? formaApresentacao : null,
      prazoApresentacao: prazoApresentacao ? prazoApresentacao : null,
      resumoAvisoAbertura: resumoAvisoAbertura ? resumoAvisoAbertura : null,
    };
    return this.http.put<any>(url, newBody, this.header);
  }

  getPeriodoVerificacao(concursoId: number) {
    const url = `${this.baseUrl}/${concursoId}/periodo-verificacao`;
    return this.http.get<any>(url, this.header);
  }

  postPeriodoVerificacao(concursoId: number, body: any) {
    const url = `${this.baseUrl}/periodo-verificacao`;
    const newBody = {
      concursoId: concursoId,
      dataInicioPeriodoVerificacao: body.dataInicioPeriodoVerificacao,
      dataFimPeriodoVerificacao: body.dataFimPeriodoVerificacao,
      dataEstimadaEnvioNotificacao: body.dataEstimadaEnvioNotificacao,
      dataInicioAudienciaInteressados: body.dataInicioAudienciaInteressados,
      dataFimAudienciaInteressados: body.dataFimAudienciaInteressados,
      abrirFaseVerificacao: body.abrirFaseVerificacao,
    };
    return this.http.post<any>(url, newBody, this.header);
  }

  putPeriodoVerificacao(id: number, body: any) {
    const url = `${this.baseUrl}/periodo-verificacao/${id}`;
    const newBody = {
      dataInicioPeriodoVerificacao: body.dataInicioPeriodoVerificacao,
      dataFimPeriodoVerificacao: body.dataFimPeriodoVerificacao,
      dataEstimadaEnvioNotificacao: body.dataEstimadaEnvioNotificacao,
      dataInicioAudienciaInteressados: body.dataInicioAudienciaInteressados,
      dataFimAudienciaInteressados: body.dataFimAudienciaInteressados,
      abrirFaseVerificacao: body.abrirFaseVerificacao,
    };
    return this.http.put<any>(url, newBody, this.header);
  }

  getFichaVerificacao(id: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${id}/ficha-verificacao`;
    return this.http.get<ResponseDefault<Array<any>>>(url, this.header);
  }

  postFichaVerificacao(newBody: any) {
    const url = `${this.baseUrl}/ficha-verificacao`;
    const formData: FormData = new FormData();

    if (newBody.urlDocumento) {
      const blob = new Blob([newBody.urlDocumento], { type: 'multipart/form-data' });
      formData.append('FormFile', blob, newBody.urlDocumento.name);
    }

    formData.append('concursoId', newBody.concursoId);
    formData.append('regiaoNuts2', newBody.regiaoNuts2);
    formData.append('areaArtistica', newBody.areaArtistica);
    formData.append('dominio', newBody.dominio);
    formData.append('fundamentacao', newBody.fundamentacao);
    formData.append('subtitulo', newBody.subtitulo);
    formData.append('descricao', newBody.descricao);
    formData.append('documento', newBody.documento);

    newBody.informacaoGeralEntidadeIds.forEach((id: number, index: number) => {
      formData.append(`informacaoGeralEntidadeIds[${index}]`, id.toString());
    });

    newBody.indicadores.forEach((indicador: { tipoIndicador: number; descricao: string }, index: number) => {
      formData.append(`indicadores[${index}][tipoIndicador]`, indicador.tipoIndicador.toString());
      formData.append(`indicadores[${index}][descricao]`, indicador.descricao);
    });

    return this.http.post(url, formData, this.header);
  }

  putFichaVerificacao(id: number, newBody: any) {
    const url = `${this.baseUrl}/${id}/ficha-verificacao`;
    const formData: FormData = new FormData();

    if (newBody.urlDocumento) {
      const blob = new Blob([newBody.urlDocumento], { type: 'multipart/form-data' });
      formData.append('FormFile', blob, newBody.urlDocumento.name);
    }

    formData.append('concursoId', newBody.concursoId);
    formData.append('regiaoNuts2', newBody.regiaoNuts2);
    formData.append('areaArtistica', newBody.areaArtistica);
    formData.append('dominio', newBody.dominio);
    formData.append('fundamentacao', newBody.fundamentacao);
    formData.append('subtitulo', newBody.subtitulo);
    formData.append('descricao', newBody.descricao);
    formData.append('documento', newBody.documento);

    newBody.informacaoGeralEntidadeIds.forEach((id: number, index: number) => {
      formData.append(`informacaoGeralEntidadeIds[${index}]`, id.toString());
    });

    newBody.indicadores.forEach((indicador: { tipoIndicador: number; descricao: string }, index: number) => {
      formData.append(`indicadores[${index}][tipoIndicador]`, indicador.tipoIndicador.toString());
      formData.append(`indicadores[${index}][descricao]`, indicador.descricao);
    });

    return this.http.put(url, formData, this.header);
  }

  getPeriodoFormalizacao(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/periodo-formalizacao`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  postPeriodoFormalizacao(concursoId: number, body: any) {
    const url = `${this.baseUrl}/periodo-formalizacao`;
    const newBody = {
      concursoId: concursoId,
      dataInicio: body.dataInicio,
      horaInicio: body.horaInicio,
      dataFim: body.dataFim,
      horaFim: body.horaFim,
    };
    return this.http.post(url, newBody, this.header);
  }

  putPeriodoFormalizacao(concursoId: number, body: any) {
    const url = `${this.baseUrl}/${concursoId}/periodo-formalizacao`;
    const newBody = {
      dataInicio: body.dataInicio,
      horaInicio: body.horaInicio,
      dataFim: body.dataFim,
      horaFim: body.horaFim,
    };
    return this.http.put(url, newBody, this.header);
  }

  getPeriodoApreciacao(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/periodo-apreciacao`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  postPeriodoApreciacao(concursoId: number, body: any) {
    const url = `${this.baseUrl}/periodo-apreciacao`;
    const newBody = {
      concursoId: concursoId,
      abrirFaseApreciacao: body.abrirFaseApreciacao,
      dataInicio: body.dataInicio,
      dataFim: body.dataFim,
      dataEstimadaEnvioNotificacao: body.dataEstimadaEnvioNotificacao,
      dataInicioAudienciaInteressados: body.dataInicioAudienciaInteressados,
      dataFimAudienciaInteressados: body.dataFimAudienciaInteressados,
    };
    return this.http.post(url, newBody, this.header);
  }

  putPeriodoApreciacao(concursoId: number, body: any) {
    const url = `${this.baseUrl}/${concursoId}/periodo-apreciacao`;
    const newBody = {
      abrirFaseApreciacao: body.abrirFaseApreciacao,
      dataInicio: body.dataInicio,
      dataFim: body.dataFim,
      dataEstimadaEnvioNotificacao: body.dataEstimadaEnvioNotificacao,
      dataInicioAudienciaInteressados: body.dataInicioAudienciaInteressados,
      dataFimAudienciaInteressados: body.dataFimAudienciaInteressados,
    };
    return this.http.put(url, newBody, this.header);
  }

  getFichaApreciacao(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/ficha-apreciacao`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getFichaApreciacaoSubmeter(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/ficha-apreciacao/submissoes`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  postFichaApreciacaoCriterio(concursoId: number, body: any) {
    const url = `${this.baseUrl}/ficha-apreciacao-criterio`;
    const newBody = {
      concursoId: concursoId,
      criterioName: body.criterioName,
      criterioDescricao: body.criterioDescricao,
      porcentagem: body.porcentagem,
    };
    return this.http.post(url, newBody, this.header);
  }

  postFichaApreciacaoSubCriterio(criterioId: number, body: any) {
    const url = `${this.baseUrl}/ficha-apreciacao-subcriterio`;
    const newBody = {
      criterioId: criterioId,
      descricao: body.descricao,
      porcentagem: body.porcentagem,
    };
    return this.http.post(url, newBody, this.header);
  }

  postFichaApreciacaoSubmeter(concursoId: number) {
    const url = `${this.baseUrl}/${concursoId}/ficha-apreciacao/submeter`;

    const newBody = {
      criterioId: concursoId,
    };
    return this.http.post(url, newBody, this.header);
  }

  postTabelaApreciacaoCandidatura(criterioId: number, body: any, candidaturaId: number) {
    const url = `${this.baseUrl}/tabela-apreciacao/consulta-candidatura`;
    const newBody = {
      criterioId: criterioId,
      notaConsenso: body.notaConsenso,
      observacao: body.observacao,
      candidaturaId: candidaturaId,
    };
    return this.http.post(url, newBody, this.header);
  }

  postTabelaApreciacaoProjetoDecisao(body: any, candidaturaId: number) {
    const url = `${this.baseUrl}/candidatura/projeto-decisao`;
    const projetoDecisao = [
      {
        candidaturaId: candidaturaId,
        montanteGlobalAtribuido: body.montanteGlobalAtribuido,
        propostaDecisaoPrevia: body.propostaDecisaoPrevia,
        audienciaInteressados: body.audienciaInteressados,
      },
    ];
    const newBody = {
      projetoDecisao: projetoDecisao,
    };
    return this.http.post(url, newBody, this.header);
  }

  postTabelaApreciacaoDecisaoFinal(body: any, candidaturaId: number) {
    const url = `${this.baseUrl}/tabela-apreciacao/decisao-final`;
    const decisaoFinal = [
      {
        candidaturaId: candidaturaId,
        decisaoFinal: body.decisaoFinal,
      },
    ];
    const newBody = {
      decisaoFinal: decisaoFinal,
    };
    return this.http.patch(url, newBody, this.header);
  }

  postComissaoApreciadores(body: any, candidaturaId: number) {
    const url = `${this.baseUrl}/${candidaturaId}/atribuir-candidaturas-manual`;
    const atribuicaoManual = [
      {
        formularioCandidaturaId: candidaturaId,
        apreciadorId: body.apreciadorId,
      },
    ];
    const newBody = {
      atribuicaoManual: atribuicaoManual,
    };
    return this.http.post(url, newBody, this.header);
  }

  deleteFichaApreciacaoSubCriterio(id: number) {
    const url = `${this.baseUrl}/ficha-apreciacao-subcriterio/${id}`;
    return this.http.delete(url, this.header);
  }

  deleteFichaApreciacaoCriterio(id: number) {
    const url = `${this.baseUrl}/ficha-apreciacao-criterio/${id}`;
    return this.http.delete(url, this.header);
  }

  getFichaApreciacaoCriterio(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/ficha-apreciacao-criterio`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getDadosNumeroCandidatura(numeroCandidatura: number): Observable<any> {
    const url = `${this.baseUrl}/tabela-apreciacao/consulta-candidatura/${numeroCandidatura}`;
    return this.http.get<ResponseDefault<any>>(url);
  }

  getFichaApreciacaoDocumentos(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/internal/ficha-apreciacao-decisao-e-documentos`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getProjetoDecisao(): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/candidatura/projeto-decisao`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getCandidaturaAudienciaInteresados(): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/formulario-candidatura/audiencia-interessados`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getTabelaDocumentos(concursoId: number, tipo: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/tabela-apreciacao/documentos/${tipo}`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getDocumentosConclusao(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/internal/documentacao-final`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getDocumentoParecerFinalAssinado(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/parecer-final-assinado`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getParecerFinalAssinado(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/internal/parecer-final-assinado/informacoes`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getHistorico(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/historico-alteracao/parecer-final-assinado/documentos`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getAvaliador(candidaturaId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/tabela-apreciacao/candidatura/${candidaturaId}/avaliador`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  postFichaApreciacao(concursoId: number, body: any) {
    const url = `${this.baseUrl}/internal/ficha-apreciacao-decisao-e-documentos`;

    const formData: FormData = new FormData();

    formData.append('concursoId', concursoId.toString());
    formData.append('fundamentacoes', body.fundamentacoes);
    formData.append('subtitulo', body.subtitulo);
    formData.append('descricao', body.descricao);
    formData.append('documentos', body.documentos);
    formData.append('formFile', body.filename, body.filename.name);
    formData.append('formFile', body.filename, body.filename.name);

    return this.http.post(url, formData, this.header);
  }

  postDocumentacaoFinal(concursoId: number, body: any, tipo: number) {
    const url = `${this.baseUrl}/tabela-apreciacao/documentos`;

    const formData: FormData = new FormData();
    formData.append('concursoId', concursoId.toString());
    formData.append('tipoDocumento', tipo.toString());
    if (body.documents && body.documents.length > 0) {
      for (let i = 0; i < body.documents.length; i++) {
        formData.append('formFile', body.documents[i], body.documents[i].name);
      }
    }
    return this.http.post(url, formData, this.header);
  }

  postDocumentacaoConclusao(concursoId: number, body: any) {
    const url = `${this.baseUrl}/internal/documentacao-final`;

    const formData: FormData = new FormData();
    formData.append('concursoId', concursoId.toString());
    if (body.documents && body.documents.length > 0) {
      for (let i = 0; i < body.documents.length; i++) {
        formData.append('formFile', body.documents[i], body.documents[i].name);
      }
    }
    return this.http.post(url, formData, this.header);
  }

  postParecerFinalAssinado(concursoId: number, body: any) {
    const url = `${this.baseUrl}/parecer-final-assinado`;

    const formData: FormData = new FormData();
    formData.append('concursoId', concursoId.toString());
    if (body.documents && body.documents.length > 0) {
      for (let i = 0; i < body.documents.length; i++) {
        formData.append('formFile', body.documents[i], body.documents[i].name);
      }
    }
    return this.http.post(url, formData, this.header);
  }

  postPublicar(concursoId: number) {
    const url = `${this.baseUrl}/${concursoId}/internal/parecer-final-assinado/publicar`;
    return this.http.post(url, this.header);
  }

  postProjetoDecisaoSubmissao(concursoId: number) {
    const url = `${this.baseUrl}/tabela-apreciacao/decisao/submeter`;
    const newBody = {
      concursoId: concursoId,
      tipoDecisao: 1,
    };
    return this.http.post(url, newBody, this.header);
  }

  postDecisaoFinalSubmissao(concursoId: number) {
    const url = `${this.baseUrl}/tabela-apreciacao/decisao/submeter`;
    const newBody = {
      concursoId: concursoId,
      tipoDecisao: 2,
    };
    return this.http.post(url, newBody, this.header);
  }

  postAjustamento(concursoId: number, body: any) {
    const url = `${this.baseUrl}/${concursoId}/ajustamento`;
    const newBody = {
      dataInicio: body.dataInicio,
      dataFinal: body.dataFinal,
    };
    return this.http.post(url, newBody, this.header);
  }

  postCandidaturasAjustamento(body: any, concursoId: number, ajustamentoId: number) {
    const url = `${this.baseUrl}/candidatura-ajustamento`;
    const newBody = {
      dataInicio: body.dataInicio,
      dataFinal: body.dataFinal,
      concursoId: concursoId,
      ajustamentoId: ajustamentoId,
    };
    return this.http.post(url, newBody, this.header);
  }

  getAjustamento(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/ajustamento`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getProjetoDecisaoSubmissao(concursoId: number, tipo: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/tabela-apreciacao/decisao/${tipo}/submissao`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getDecisaoFinalSubmissao(concursoId: number, tipo: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/tabela-apreciacao/decisao/${tipo}/submissao`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getMonitorizacaoAjustamento(concursoId: number, ajustamentoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/monitorizacao-ajustamento/${ajustamentoId}`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getListaMonitorizacaoAjustamento(ajustamentoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/listagem-monitorizacao-ajustamento/${ajustamentoId}`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  putAjustamento(concursoId: number, body: any) {
    const url = `${this.baseUrl}/ajustamento/${concursoId}`;
    const newBody = {
      dataInicio: body.dataInicio,
      dataFinal: body.dataFinal,
    };
    return this.http.put(url, newBody, this.header);
  }

  getFormalizacaoPagamento(concursoId: number): Observable<ResponseDefault<Array<IFormalizacaoPagamento>>> {
    const url = `${this.baseUrl}/${concursoId}/formalizacao-pagamento`;
    return this.http.get<ResponseDefault<Array<IFormalizacaoPagamento>>>(url, this.header);
  }

  postFormalizacaoPagamento(concursoId: number, filedata: File) {
    const url = `${this.baseUrl}/${concursoId}/formalizacao-pagamento`;
    const formData: FormData = new FormData();
    const blob = new Blob([filedata], { type: 'multipart/form-data' });
    formData.append('file', blob, filedata.name);
    return this.http.post(url, formData, this.header);
  }

  getEstadoFormalizacaoPagamento(concursoId: number): Observable<ResponseDefault<Array<IEstadoFormalizacaoPagamento>>> {
    const url = `${this.baseUrl}/${concursoId}/historico-importacao-pagamento`;
    return this.http.get<ResponseDefault<Array<IEstadoFormalizacaoPagamento>>>(url, this.header);
  }

  downloadFormalizacaoPagamentoPorImportacao(importcaoId: number) {
    const url = `${this.baseUrl}/formalizacao-pagamento/historico/${importcaoId}/download`;

    const headers = new HttpHeaders();
    headers.set('Authorization', `Bearer ${this.token}`);

    headers.set('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

    return this.http.get(url, { headers, responseType: 'arraybuffer' });
  }

  postDocumentosAvisoAbertura(avisoAberturaId: number, tipoDocumentoAbertura: number, filedata: File) {
    const url = `${this.baseUrl}/aviso-abertura-documentos`;

    const formData: FormData = new FormData();
    const blob = new Blob([filedata], { type: 'multipart/form-data' });
    formData.append('formFile', blob, filedata.name);
    formData.append('nome', filedata.name);
    formData.append('avisoAberturaId', JSON.parse(JSON.stringify(avisoAberturaId)));
    formData.append('tipoDocumentoAbertura', JSON.parse(JSON.stringify(tipoDocumentoAbertura)));

    return this.http.post(url, formData, this.header);
  }

  postFormularioCandidatura(concursoId: number, body: any) {
    const url = `${this.baseUrl}/formulario-candidatura`;
    const newBody = {
      concursoId: concursoId,
      nomeProjeto: body.nomeProjeto,
      areaArtisticaId: body.areaArtisticaId,
      dominioArtisticoId: body.dominioId,
      patamarFinanceiro: body.patamarFinanceiro,
      nuts2Id: body.regiaoMaiorAtividade,
      sinopse: body.sinopse,
    };
    return this.http.post(url, newBody, this.header);
  }

  putFormularioCandidatura(concursoId: number, body: any) {
    const url = `${this.baseUrl}/external/minhas-candidaturas/${concursoId}`;
    const newBody = {
      nomeProjeto: body.nomeProjeto,
      areaArtisticaId: body.areaArtisticaId,
      dominioArtisticoId: body.dominioId,
      patamarFinanceiro: body.patamarFinanceiro,
      nuts2Id: body.regiaoMaiorAtividade,
      sinopse: body.sinopse,
    };
    return this.http.put(url, newBody, this.header);
  }

  getMinhasCandidaturas(candidaturaId?: number): Observable<ResponseDefault<Array<any>>> {
    let url = `${this.baseUrl}/external/minhas-candidaturas${candidaturaId ? `?candidaturaId=${candidaturaId}` : ''}`;
    return this.http.get<ResponseDefault<Array<any>>>(url, this.header);
  }

  deleteMinhaCandidatura(minhaCandidaturaId: number) {
    const url = `${this.baseUrl}/external/deletar-candidatura/${minhaCandidaturaId}`;
    return this.http.delete(url, this.header);
  }

  deleteDocumentos(documentoId: number) {
    const url = `${this.baseUrl}/tabela-apreciacao/documentos/${documentoId}`;
    return this.http.delete(url, this.header);
  }

  deleteDocumentosConclusao(documentoId: number) {
    const url = `${this.baseUrl}/internal/documentacao-final/${documentoId}`;
    return this.http.delete(url, this.header);
  }

  deleteParecerFinalAssinado(documentoId: number, concursoId: number) {
    const url = `${this.baseUrl}/${concursoId}/parecer-final-assinado/${documentoId}`;
    return this.http.delete(url, this.header);
  }

  // get lista de entidades das candidaturas atuais num concurso
  getListaEntidadesCandidaturasConcurso(concursoId?: number): Observable<ResponseDefault<Array<any>>> {
    let url = `${this.baseUrl}/${concursoId}/verificacao/candidaturas`;
    return this.http.get<ResponseDefault<Array<any>>>(url, this.header);
  }
  getVerificacoesCandidaturas(concursoId = '', estado = '', candidaturaId = '') {
    const url = `${this.baseUrl}/buscar-candidaturas?estado=${estado}&concursoId=${concursoId}&candidaturaId=${candidaturaId}`;
    return this.http.get<ResponseDefault<VerificacaoCandidaturaGetArray>>(url, this.header);
  }

  postVerificarCandidaturas(candidaturaId: number) {
    const url = `${this.baseUrl}/verificar-candidaturas`;
    return this.http.post(url, { candidaturaId }, this.header);
  }

  deleteDocumento(candidaturaId: string, documentoId: number) {
    const url = `${this.baseUrl}/${candidaturaId}/ajustamento/documento/${documentoId}`;
    return this.http.delete(url, this.header);
  }

  postCandidaturasAutomatica(candidaturaId: number) {
    const url = `${this.baseUrl}/${candidaturaId}/atribuir-candidaturas-automatico`;
    return this.http.post(url, this.header);
  }

  getCandidaturasManual(candidaturaId: number) {
    const url = `${this.baseUrl}/${candidaturaId}/atribuir-candidaturas-manual`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getMonitorizacao(candidaturaId: number) {
    const url = `${this.baseUrl}/${candidaturaId}/apreciacao/monitorizacao`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getElementos(candidaturaId: number) {
    const url = `${this.baseUrl}/${candidaturaId}/comissao-apreciacao`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }
  postAvaliacaoSubcriterio(subcriterioId: number, candidaturaId: number, body: any) {
    const url = `${this.baseUrl}/tabela-apreciacao/candidatura/avaliacao-subcriterio`;
    const subcriterios = [
      {
        subcriterioId: subcriterioId,
        nota: body.nota,
        observacao: body.observacao,
      },
    ];
    const newBody = {
      candidaturaId: candidaturaId,
      subcriterios,
    };
    return this.http.post(url, newBody, this.header);
  }

  getAvaliacaoSubcriterio(candidaturaId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/tabela-apreciacao/candidatura/${candidaturaId}/avaliacao-subcriterio`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getCandidaturasAvaliar(): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/candidaturas-para-apreciar`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  postFundamentacoes(candidaturaId: number, body: any) {
    const url = `${this.baseUrl}/ficha-apreciacao/avaliacao/candidatura/fundamentacoes`;
    const newBody = {
      descricao: body.descricao,
      candidaturaId: candidaturaId,
    };
    return this.http.post(url, newBody, this.header);
  }

  getFundamentacoes(id: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/ficha-apreciacao/avaliacao/candidatura/${id}/fundamentacoes`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  postDocumentosAvaliacao(candidaturaId: number, body: any) {
    const url = `${this.baseUrl}/ficha-apreciacao/candidatura/avaliacao/documentos`;

    const formData: FormData = new FormData();
    formData.append('candidaturaId', candidaturaId.toString());
    if (body.documents && body.documents.length > 0) {
      for (let i = 0; i < body.documents.length; i++) {
        formData.append('formFile', body.documents[i], body.documents[i].name);
      }
    }
    return this.http.post(url, formData, this.header);
  }

  deleteDocumentosAvaliacao(documentoId: number) {
    const url = `${this.baseUrl}/ficha-apreciacao/avaliacao/candidatura/documentos/${documentoId}`;
    return this.http.delete(url, this.header);
  }

  getDocumentosAvaliacao(candidaturaId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/ficha-apreciacao/avaliacao/candidatura/${candidaturaId}/documentos`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  postApreciaoAudiencia(concursoId: number, tipo: number, dataInicio: Date | string, dataFim: Date | string) {
    const url = `${this.baseUrl}/apreciacao/audiencia-interessados/periodo-submissao`;
    const newBody = {
      concursoId: concursoId,
      dataInicio: dataInicio,
      dataFim: dataFim,
      tipo: tipo,
    };
    return this.http.post(url, newBody, this.header);
  }

  postApreciaoAudiencia2(concursoId: number, tipo: number, dataInicio: Date | string, dataFim: Date | string) {
    const url = `${this.baseUrl}/apreciacao/audiencia-interessados/periodo-submissao`;
    const newBody = {
      concursoId: concursoId,
      dataInicio: dataInicio,
      dataFim: dataFim,
      tipo: tipo,
    };
    return this.http.post(url, newBody, this.header);
  }

  putApreciacaoAudiencia(id: number, dataInicio: Date | string, dataFim: Date | string) {
    const url = `${this.baseUrl}/apreciacao/audiencia-interessados/periodo-submissao/${id}`;
    const newBody = {
      dataInicio: dataInicio,
      dataFim: dataFim,
    };
    return this.http.put(url, newBody, this.header);
  }

  putApreciacaoAudiencia2(id: number, dataInicio: Date | string, dataFim: Date | string) {
    const url = `${this.baseUrl}/apreciacao/audiencia-interessados/periodo-submissao/${id}`;
    const newBody = {
      dataInicio: dataInicio,
      dataFim: dataFim,
    };
    return this.http.put(url, newBody, this.header);
  }

  getApreciacaoAudiencia(concursoId: number, tipo: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/apreciacao/audiencia-interessados/periodo-submissao/${tipo}`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getApreciacaoAudiencia2(concursoId: number, tipo: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/apreciacao/audiencia-interessados/periodo-submissao/${tipo}`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getAvaliadores(concursoId: number, candidaturaId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/candidatura/${candidaturaId}/apreciacao/avaliadores`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getPontuacaoFinal(concursoId: number, candidaturaId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/candidatura/${candidaturaId}/tabela-apreciacao/pontuacao-final`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getTodasPontuacoes(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/candidatura/tabela-apreciacao/todas-pontuacoes`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  // CANDIDATURA UTILIZADOR ATRAVES DO SURVEYJS =================================

  getExistingSurveyAnswersCandidaturaById(candidaturaId: number): Observable<any> {
    return this.http.get(this.baseUrl + '/formulario/candidatura/respostas/' + candidaturaId);
  }

  postUserSurveyAnswersData(surveyDataStringified: string, concursoId: number, formularioSurveyId: number, nomeCandidatura: string): Observable<any> {
    // this endpoint should return the newly created candidaturaId from backend
    let body = {
      concursoId: concursoId,
      formularioId: formularioSurveyId,
      jsonTexto: surveyDataStringified,
      nomeCandidatura: nomeCandidatura,
    };
    return this.http.post(this.baseUrl + '/formulario/candidatura/respostas', body);
  }

  updateUserSurveyAnswersData(surveyDataStringified: string, candidaturaId: number, submeterCandidatura?: boolean): Observable<any> {
    let body = {
      candidaturaId: candidaturaId,
      jsonTexto: surveyDataStringified,
      submeterCandidatura: submeterCandidatura ?? false,
    };
    return this.http.put(this.baseUrl + '/formulario/candidatura/respostas', body);
  }

  deleteUserSurveyAnswersByCandidaturaId(candidaturaId: any): Observable<any> {
    return this.http.delete(this.baseUrl + '/formulario/candidatura/respostas/' + candidaturaId);
  }

  // FIM CANDIDATURA UTILIZADOR SURVEYS =========================================

  getVerificacaoAudiencia(candidaturaId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/verificacao/candidatura/${candidaturaId}/audiencia-interessados-periodo-submissao`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  postVerificacaoAudiencia(concursoId: number, candidaturaId: number, dataInicio: Date | string, dataFim: Date | string) {
    const url = `${this.baseUrl}/verificacao/candidatura/audiencia-interessados-periodo-submissao`;
    const newBody = {
      concursoId: concursoId,
      candidaturaId: candidaturaId,
      dataInicio: dataInicio,
      dataFim: dataFim,
    };
    return this.http.post(url, newBody, this.header);
  }

  putVerificacaoAudiencia(id: number, dataInicio: Date | string, dataFim: Date | string) {
    const url = `${this.baseUrl}/verificacao/candidatura/audiencia-interessados-periodo-submissao/${id}`;
    const newBody = {
      dataInicio: dataInicio,
      dataFim: dataFim,
    };
    return this.http.put(url, newBody, this.header);
  }

  postNotificacoes(candidaturaId: number, body: any) {
    const url = `${this.baseUrl}/candidatura/${candidaturaId}/verificacao/enviar-notificacao`;
    const newBody = {
      candidaturaId: candidaturaId,
      estadoVerificacaoFinal: body.estadoVerificacaoFinal,
    };
    return this.http.post(url, newBody, this.header);
  }

  getMembrosManual(concursoId: number, numeroCandidatura: string, areaArtistica: string, dominio: string, tipoFase: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/candidaturas-por-concurso`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getElementosComissao(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/comissao-acompanhamento/membros-atribuidos`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  postElementosComissao(body: any, candidaturaId: number, concursoId: number, faseConcurso: number) {
    const url = `${this.baseUrl}/comissao-acompanhamento/atribuir-candidatura-manual`;
    const atribuicao = [
      {
        formularioCandidaturaId: candidaturaId,
        apreciadorIds: body.apreciadorIds,
      },
    ];
    const newBody = {
      concursoId: concursoId,
      atribuicao: atribuicao,
      faseConcurso: faseConcurso,
    };
    return this.http.post(url, newBody, this.header);
  }

  getComissaoMonotorizacao(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/comissao-acompanhamento/monitorizacao`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  deleteMonotorizacao(concursoId: number, candidaturaId: number) {
    const url = `${this.baseUrl}/${concursoId}/comissao-acompanhamento/candidatura/${candidaturaId}/monitorizacao`;
    return this.http.delete(url, this.header);
  }

  postRelatorios(concursoId: number, tipo: number) {
    const url = `${this.baseUrl}/${concursoId}/configuracao-parecer/disponibilizar-relatorio/${tipo}`;
    const newBody = {
      id: concursoId,
      tipo: tipo,
    };
    return this.http.patch(url, newBody, this.header);
  }

  getExecucaoFinanceira(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/internal/execucao-financeira`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  putRecibo(body: any, id: number) {
    const url = `${this.baseUrl}/execucao/pagamento`;
    const newBody = {
      id: id,
      recibo: body.recibo,
    };
    return this.http.put(url, newBody, this.header);
  }

  getExportDocumentos(reciboId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/execucacao-financeira/${reciboId}/documento`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getMaisInformacao(numeroSgi: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/execucao-financeira/external/${numeroSgi}/mais-informacoes`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getMaisInformacaoPagamentos(numeroSgi: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/execucao-financeira/mais-informacao/${numeroSgi}/pagamentos`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  deleteUser(concursoId: number, externalUserId: string) {
    const url = `${this.baseUrl}/${concursoId}/acesso/remover-usuario/${externalUserId}`;
    return this.http.delete(url, this.header);
  }

  postCalendarizacaoConclusao(body: any, concursoId: number) {
    const url = `${this.baseUrl}/internal/calendarizacao-conclusao`;
    const newBody = {
      concursoId: concursoId,
      abrirFaseConclusao: body.abrirFaseConclusao,
      dataInicio: body.dataInicio,
      dataFim: body.dataFim,
      horaInicio: body.horaInicio,
      horaFim: body.horaFim,
      dataEstimadaEnvioNotificacao: body.dataEstimadaEnvioNotificacao,
      audienciaInteressadosDataInicio: body.audienciaInteressadosDataInicio,
      audienciaInteressadosDataFim: body.audienciaInteressadosDataFim,
      audienciaInteressadosHoraInicio: body.audienciaInteressadosHoraInicio,
      audienciaInteressadosHoraFim: body.audienciaInteressadosHoraFim,
      submissaoParecerFinalDataInicio: body.submissaoParecerFinalDataInicio,
      submissaoParecerFinalDataFim: body.submissaoParecerFinalDataFim,
      submissaoParecerFinalHoraInicio: body.submissaoParecerFinalHoraInicio,
      submissaoParecerFinalHoraFim: body.submissaoParecerFinalHoraFim,
    };
    return this.http.post(url, newBody, this.header);
  }

  putCalendarizacaoConclusao(body: any, calendarizacaoId: number) {
    const url = `${this.baseUrl}/internal/calendarizacao-conclusao/${calendarizacaoId}`;
    const newBody = {
      abrirFaseConclusao: body.abrirFaseConclusao,
      dataInicio: body.dataInicio,
      dataFim: body.dataFim,
      horaInicio: body.horaInicio,
      horaFim: body.horaFim,
      dataEstimadaEnvioNotificacao: body.dataEstimadaEnvioNotificacao,
      audienciaInteressadosDataInicio: body.audienciaInteressadosDataInicio,
      audienciaInteressadosDataFim: body.audienciaInteressadosDataFim,
      audienciaInteressadosHoraInicio: body.audienciaInteressadosHoraInicio,
      audienciaInteressadosHoraFim: body.audienciaInteressadosHoraFim,
      submissaoParecerFinalDataInicio: body.submissaoParecerFinalDataInicio,
      submissaoParecerFinalDataFim: body.submissaoParecerFinalDataFim,
      submissaoParecerFinalHoraInicio: body.submissaoParecerFinalHoraInicio,
      submissaoParecerFinalHoraFim: body.submissaoParecerFinalHoraFim,
    };
    return this.http.put(url, newBody, this.header);
  }

  getCalendarizacaoConclusao(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/internal/calendarizacao-conclusao`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getConclusaoRelatorioFinal(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/conclusao/${concursoId}/listagem-relatorio-final`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getConclusaoAudienciaInteressados(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/conclusao/internal/audiencias-interessados`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }

  getPublicar(concursoId: number): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/${concursoId}/internal/parecer-final/assinado/publicado`;
    return this.http.get<ResponseDefault<any>>(url, this.header);
  }
}
