<ul class="menu-list">
  <li *ngFor="let link of links">
    <span *ngIf="link.show"
    [ngClass]="{ 'greyed-out-text': link?.greyedOutBoolean }"
    [matTooltip]="link?.tooltip ?? ''"
    matTooltipPosition="right"
    (click)="navigate(link)"
    class="animate-slide-down">
      {{ link.title }}
    </span>
  </li>
</ul>
