import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoEquipaRH'
})
export class TipoEquipaRHPipe implements PipeTransform {

  transform(value: number): string {
    const tipoEquipaRH = [
      'Permanente', 
      'Pontual'
    ]

    return tipoEquipaRH[value] || '';
  }

}
