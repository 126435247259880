import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { faHouse, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../services/user.service';

export interface breadcrumbListData {
  path: string;
  title: string;
  disableClick?: boolean; // shows the title but doesnt let the user click it
  startPathFromRoot?: boolean;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnChanges {
  faHouse = faHouse;
  faAngleRight = faAngleRight;

  @Input() breadcrumbList!: breadcrumbListData[];
  @Input() helpButton: boolean = true;
  existe = false;

  ShowState() {
    this.existe = !this.existe;
  }

  userLogged = this.userService.getUserName();

  constructor(private userService: UserService) {}

  ngOnChanges(): void {
    if (this.breadcrumbList.length >= 1) {
      this.breadcrumbList.forEach((route, index) => {
        if (route?.startPathFromRoot) {
          if (!route.path.startsWith('/')) {
            route.path = '/' + (route.path ?? '');
          }
        } else {
          // routes com index acima de 0, têm de incluir a rota 0 como base
          if (index > 0) {
            route.path = this.breadcrumbList[0]?.path + '/' + (route.path ?? '');
          } else {
            // index 0
            if (!route.path.startsWith('/')) {
              route.path = '/' + (route.path ?? '');
            }
          }
        }
      });
    }
  }
}
