import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AuthorizationGuard } from './core/authentication/authorization.guard';
import { RtcpComponent } from './pages/rtcp/rtcp.component';
1;

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'home',
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('./pages/authenticated-home/authenticated-home.module').then(m => m.AuthenticatedHomeModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'registar-utilizador',
    loadChildren: () => import('./pages/register-user/register-user.module').then(m => m.RegisterUserModule),
  },
  {
    path: 'trocar-palavra-chave/:id',
    loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule),
  },
  {
    path: 'esqueci-palavra-chave',
    loadChildren: () => import('./pages/recover-password/recover-password.module').then(m => m.RecoverPasswordModule),
  },
  {
    path: 'interno/authorized',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'validar-email/:id',
    loadChildren: () => import('./pages/confirm-email/confirm-email.module').then(m => m.ConfirmEmailModule),
  },
  {
    path: 'e-registo',
    loadChildren: () => import('./pages/e-registo/e-registo.module').then(m => m.ERegistoModule),
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'interno',
    // canActivate: [AuthorizationGuard],
    loadChildren: () => import('./pages/interno/interno.module').then(m => m.InternoModule),
  },
  {
    path: 'balcao-artes',
    loadChildren: () => import('./pages/balcao-artes/balcao-artes.module').then(m => m.BalcaoArtesModule),
  },
  {
    path: 'bolsa-consultores-especialistas',
    loadChildren: () => import('./pages/bolsa-consultores-especialistas/bolsa-consultores-especialistas.module').then(m => m.BolsaConsultoresEspecialistasModule),
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'avaliacao-concursos',
    loadChildren: () => import('./pages/avaliacao-concursos/avaliacao-concursos.module').then(m => m.AvaliacaoConcursosModule),
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'programas-de-apoio',
    loadChildren: () => import('./pages/programas-de-apoio/programas-de-apoio.module').then(m => m.ProgramasDeApoioModule),
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'programas-de-apoio/candidatura',
    loadChildren: () => import('./pages/candidatura/candidatura.module').then(m => m.CandidaturaModule),
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'rtcp',
    loadChildren: () => import('src/app/pages/rtcp/rtcp.module').then(m => m.RtcpModule),
    canActivate: [AuthorizationGuard],
  },
  { path: 'rpac', loadChildren: () => import('./pages/rpac/rpac.module').then(m => m.RpacModule), canActivate: [AuthorizationGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
