import { Component, Input, OnInit } from '@angular/core';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faBars, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../authentication/auth.service';
import { ILinks } from 'src/app/shared/interfaces/link.interface';
import { individualLinks } from './header-data/individual-link';
import { internalLinks } from './header-data/internal-link';
import { FormControl } from '@angular/forms';
import { coletivolLinks } from './header-data/coletivo-link';
import { auxiliarLinks } from './header-data/auxiliar-link';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  links: Array<ILinks> = new Array();
  @Input() userType = '';
  @Input() userName = '';

  menuClosingAnimation = false;

  faSearch = faSearch;
  faBell = faBell;
  faBars = faBars;
  faXmark = faXmark;

  isMenuOpen = false;
  userColetivoArrayExternalUsers = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '[]')?.externalUsers : null;

  userColetivoControl = new FormControl();
  matSelectTriggerLabel = '';

  constructor(private authService: AuthService, private router: Router, private userService: UserService, private dialog: Dialog) {}

  ngOnInit(): void {
    this.resetUserLinks();

    this.setUserMenu();
  }

  resetUserLinks(): void {
    if (this.userType === 'ExternalIndividual') {
      this.links = individualLinks;
    } else if (this.userType === 'InternalUser') {
      this.links = internalLinks;
    } else if (this.userType === 'UserAuxiliar') {
      this.links = auxiliarLinks;
    } else if (this.userType === 'ExternalGroup') {
      this.links = coletivolLinks;
    }
  }

  setUserMenu(): void {
    if (this.userType === 'ExternalIndividual' || this.userType === 'UserAuxiliar') { 
      const myUser = this.userService.getLocalUserData();
      let newColetivoArray = [
        // se for user coletivo, temos tambem que adicionar uma opcao na lista de users dropdown para escolher-se a si proprio,
        // para conseguir aceder ao e-registo e coisas dele proprio - colocar no index 0
        {
          ...myUser,
          ...{
            nome: myUser?.nomeComum ?? 'O Meu Utilizador',
            userProprio: true,
          },
        },
      ];

      if (this.userColetivoArrayExternalUsers?.length > 0) {
        this.userColetivoArrayExternalUsers.forEach((user: any) => newColetivoArray.push({ ...user, ...{ userExternal: true } }));
      }

      this.userColetivoArrayExternalUsers = newColetivoArray;
  
      this.userService.setOriginalUserTokenAndId(localStorage.getItem('userToken')?.replaceAll('"', '') ?? '', newColetivoArray[0]?.id ?? localStorage.getItem('userId') ?? ''); // replaceAll " because userToken is saved with "" IN THE STRING

      // verifica se estava um user coletivo selecionado anteriormente e restaura a seleção
      const userSelectedAnterior = localStorage.getItem('selecteduserColetivo') ? JSON.parse(localStorage.getItem('selecteduserColetivo')!) : undefined;
      const foundUserColetivo = this.userColetivoArrayExternalUsers.find((user: any) => user.id === userSelectedAnterior?.id);
      if (userSelectedAnterior && foundUserColetivo) {
        this.userColetivoControl.patchValue(foundUserColetivo?.id);
        this.setMatSelectTriggerLabel(foundUserColetivo?.nome);
        this.setSelectedUser(foundUserColetivo?.id, true);
      } else {
        this.userColetivoControl.patchValue(newColetivoArray[0]?.id);
        this.setMatSelectTriggerLabel(newColetivoArray[0]?.nome);
        this.setSelectedUser(newColetivoArray[0]?.id, false); // set initial user nele proprio, e dá redirect home
      }
    }
  }

  logout() {
    this.closeMenu();
    this.authService.logout();
  }

  toggleMenu(event: boolean) {
    if (event === true) {
      this.isMenuOpen = true;
    } else {
      this.closeMenu();
    }
  }

  closeMenu(): void {
    this.menuClosingAnimation = true;
    setTimeout(() => {
      this.isMenuOpen = false;
      this.menuClosingAnimation = false;
    }, 300);
  }

  setMatSelectTriggerLabel(newLabel: string): void {
    this.matSelectTriggerLabel = newLabel;
  }

  setSelectedUser(userId?: any, skipRouteRedirect?: boolean) {
    // funcao que trata de quando um user individual seleciona a consulta de outro user da lista de users a que ele tem acesso (pelos recursos humanos)

    this.resetUserLinks(); // reset menu links
    localStorage.removeItem('selecteduserColetivo');
    this.userService.useOriginalUserTokenAndIdAndClear(); // reset user

    let foundUser: any = undefined;

    if (userId) {
      foundUser = this.userColetivoArrayExternalUsers.find((user: any) => user.id === userId);
    } else {
      foundUser = this.userColetivoArrayExternalUsers.find((user: any) => user.id === this.userColetivoControl.value);
    }

    this.setMatSelectTriggerLabel(foundUser?.nome ?? 'Utilizador Próprio');

    if (foundUser && foundUser?.userExternal) {
      this.userService.selectNewEntidadeFromRecHumanos(foundUser);

      if (this.userService.currentlySelectedEntidadeRecHumanos) {
        this.links = coletivolLinks; // quando estamos a aceder a outra entidade, é sempre coletivo
        localStorage.setItem('selecteduserColetivo', JSON.stringify(foundUser));

        // mostrar um loading para o user nao fazer nada enquanto nao responder as permissões do BE
        let loading = this.dialog.open(DialogComponent, { data: { type: 'loading' } });

        this.authService
          .getPermissionsForUser(foundUser.id, this.userColetivoArrayExternalUsers[0]?.id)
          .pipe(take(1))
          .subscribe({
            next: (result: any) => {
              if (result?.data && typeof result.data === 'object') {
                // convert the object to an array
                let permissionsArray = Object.keys(result.data).map(function (key) {
                  return { id: key, tipo: result.data[key] };
                });
                this.userService.setRoutesPermissionsArray(permissionsArray);
              } else {
                console.error(`# User selecionado não tem permissões configuradas ou ocorreu um erro ao tentar obter as mesmas. A usar permissões por defeito.`);
                this.dialog.open(DialogComponent, { data: { type: 'custom-error', message: ['Ocorreu um erro ao obter as permissões para este utilizador ou o mesmo ainda não as tem configuradas.'] } });
                
                // voltar a selecionar o utilizador proprio
                this.userColetivoControl.patchValue(this.userColetivoArrayExternalUsers[0]?.id);
                this.setSelectedUser(this.userColetivoArrayExternalUsers[0]?.id);
              }
              loading.close();
            },
            error: error => {
              loading.close();
              console.error(`# User selecionado não tem permissões configuradas ou ocorreu um erro ao tentar obter as mesmas.`);
              this.dialog.open(DialogComponent, { data: { type: 'custom-error', message: ['Ocorreu um erro ao obter as permissões para este utilizador ou o mesmo ainda não as tem configuradas.'] } });

              // voltar a selecionar o utilizador proprio
              this.userColetivoControl.patchValue(this.userColetivoArrayExternalUsers[0]?.id);
              this.setSelectedUser(this.userColetivoArrayExternalUsers[0]?.id);
            },
          });
      }
    }

    if (!skipRouteRedirect) {
      // forcar o user a ir para home apos alterar a conta
      this.router.navigate(['home']);
    }
  }
}
