import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'AnosExperienciaProfissionalBolsa'})
export class AnosExperienciaProfissionalBolsa implements PipeTransform {
  transform(value: number): string {
    const anosExperienciaProfissionalBolsa = [
      '3 a 5 anos', 
      '5 a 10 anos', 
      '10 a 15 anos', 
      '+ 15 anos'
    ]

    return anosExperienciaProfissionalBolsa[+value - 1];
  }
}



