import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estadosBOtoFOBolsaConsultoresEspecialistas',
})
export class EstadosBOtoFOBolsaConsultoresEspecialistasPipe implements PipeTransform {
  transform(value: number): string {
    /*
      ESTADOS POSSIVEIS:
      1 - Submetido,
      2 - InscricaoCessada,
      3 - Aceite,
      4 - Indeferido,
      5 - EmAnalise, 
      6 - EmAnalise
    */

    if (value === 1) {
      return 'Submetido';
    } else if (value === 2) {
      return 'Inscrição Cessada';
    } else if (value === 3) {
      return 'Aceite';
    } else if (value === 4) {
      return 'Indeferido';
    } else if (value === 5 || value === 6) {
      return 'Em Análise';
    } else return '';
  }
}
