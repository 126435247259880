import { Dialog } from '@angular/cdk/dialog';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import {
  faCheckCircle,
  faEdit,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import { DialogComponent } from '../../dialog/dialog.component';

@Component({
  selector: 'app-dotted-list-item',
  templateUrl: './dotted-list-item.component.html',
  styleUrls: ['./dotted-list-item.component.scss'],
})
export class DottedListItemComponent {
  @Input() title = '';
  @Input() showEdit = false;
  @Input() showDelete = false;
  @Output() edited = new EventEmitter();
  @Output() deleted = new EventEmitter();

  showTop = true;
  showBottom = true;
  isEditing = false;

  faTrash = faTrashAlt;
  faEdit = faEdit;
  faCheck = faCheckCircle;
  faTimesCircle = faTimesCircle
  titleBkp = "";

  @ViewChild('accordion') accordion!: MatExpansionPanel;

  constructor(private dialog: Dialog){
  }

  removeTopLine() {
    this.showTop = false;
  }

  removeBottomLine() {
    this.showBottom = false;
  }

  onEdit(element: HTMLInputElement, event: Event) {
    event?.stopPropagation();
    this.titleBkp = this.title;
    this.isEditing = true;
    setTimeout(() => {
      element.focus();
    }, 100);
  }

  editDone(event: Event) {
    event?.stopPropagation();
    if(!this.title || this.title.length < 2) return;
    this.isEditing = false;
    this.titleBkp = this.title;
    this.edited.emit(this.title);
  }

  cancelEdit(event: Event) {
    event?.stopPropagation();
    this.isEditing = false;
    this.title = this.titleBkp;
  }

  onDelete(event: Event) {
    event?.stopPropagation();
    this.dialog.open(DialogComponent, {
      data: {type: 'confirm_action', confirmText: 'excluir'}
    })
    .closed
    .subscribe((res) => {
      if(!!res){
        this.deleted.emit(this.title);
      }
    })
  }

}
