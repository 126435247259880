<header *ngIf="this.userType">
  <a class="logo-container" [routerLink]="userName ? '/home' : '/'" (click)="toggleMenu(false)">
    <img class="image-logo" src="./assets/imgs/logo_dgartes.svg" alt="dgartes_logo-republica_portuguesa" />
  </a>
  <div class="user-buttons-container">
    <fa-icon class="header-icon me-5" [icon]="faSearch" (click)="toggleMenu(false)" matTooltip="Disponível brevemente" />
    <fa-icon class="header-icon me-5" [icon]="faBell" (click)="toggleMenu(false)" matTooltip="Disponível brevemente" />

    <a *ngIf="this.userType !== 'UserAuxiliar' && this.userType !== 'ExternalIndividual'" class="header-icon me-5 d-flex align-items-center justify-center select-coletivo" (click)="toggleMenu(false)">
      <strong>{{ userName }}</strong>
    </a>
    <mat-form-field
      matTooltip="Alterar entre contas ou entidades associadas à sua conta"
      class="header-icon me-5 d-flex align-items-center justify-center select-coletivo"
      *ngIf="this.userType === 'UserAuxiliar' || this.userType === 'ExternalIndividual'"
      appearance="outline"
      color="accent"
      (click)="toggleMenu(false)">
      <mat-select [formControl]="userColetivoControl" name="user" (selectionChange)="setSelectedUser()">
        <mat-select-trigger>
          <!-- o que aparece na label da trigger -->
          {{ matSelectTriggerLabel || 'Utilizador' }}
        </mat-select-trigger>
        <mat-option *ngFor="let user of userColetivoArrayExternalUsers" [value]="user.id" style="line-height: 1 !important">
          <mat-icon *ngIf="user?.userProprio" style="margin-right: 5px !important; font-size: 22px; line-height: 1.2">account_circle</mat-icon>
          <mat-icon *ngIf="user?.userExternal" style="margin-right: 5px !important; font-size: 22px; line-height: 1.2">supervised_user_circle</mat-icon>
          <strong>{{ user.nome }}</strong>
          <ng-container *ngIf="user?.userProprio">
            <br />
            <span style="font-size: 11px; color: grey !important">Conta individual</span>
          </ng-container>
          <ng-container *ngIf="user?.userExternal">
            <br />
            <span style="font-size: 11px; color: grey !important">Conta coletiva</span>
          </ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <fa-icon *ngIf="!isMenuOpen" class="header-icon animate-closed" [icon]="faBars" (click)="toggleMenu(true)" />
    <fa-icon *ngIf="isMenuOpen" class="header-icon animate-opened" [icon]="faXmark" (click)="toggleMenu(false)" />
  </div>
</header>

<div *ngIf="isMenuOpen" class="menu row animate-slide-down" [ngClass]="{ 'animate-slide-up-fadeout': menuClosingAnimation }">
  <nav class="col-lg-4">
    <app-header-link [links]="links" [isMenuOpen]="isMenuOpen" (setIsMenuOpen)="toggleMenu($event)" />
    <button
      id="logout-button-menu"
      class="animate-slide-down"
      style="margin-left: 14px"
      mat-flat-button
      type="button"
      color="primary"
      (click)="logout()"
      matTooltip="Terminar a sessão e sair da conta">
      Terminar Sessão
    </button>
  </nav>

  <app-contatos-list class="col-lg-4" />

  <img src="./assets/imgs/artes_home.png" alt="placeholder" class="animate-slide-down" style="opacity: 0; animation-delay: 0.1s; width: 100%; max-width: 450px; object-fit: contain" />
</div>

<header *ngIf="!this.userType">
  <a href="/" class="info">
    <img src="./assets/imgs/logo_dgartes.svg" alt="dgartes_logo-republica_portuguesa" />
  </a>

  <a [routerLink]="false ? '/e-registo' : '/login'" class="info reservada">Área reservada</a>
</header>
