import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estadoVerificacaoCandidatura',
})
export class EstadoVerificacaoCandidaturaPipe implements PipeTransform {
  transform(value: number): string {
    // Por Verificar = 1
    // Em Curso = 2
    // Concluido = 3

    const estadoVerificacaoCandidatura = ['Por Verificar', 'Em Curso', 'Concluido'];

    return estadoVerificacaoCandidatura[value - 1];
  }
}
