import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estadoCandidatura'
})
export class EstadoCandidaturaPipe implements PipeTransform {
  // EmPreenchimento = 1,
  // Submetida = 2,
  // Eliminada = 3
  transform(value: number): string {
    const estadosCandidatura = [
      'Em preenchimento',
      'Submetida',
      'Eliminada'
    ]

    return estadosCandidatura[+value - 1];
  }
}
