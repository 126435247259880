import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separador'
})
export class Separador implements PipeTransform {

  transform(value: number): string {
    const separador = [
      '',
      'RecursosHumanos',
      'Instalacoes',
      'AcessoPublico',
      'ColecaoPropria',
      'EnquadramentoInstitucional',
      'RecursosFinanceiros',
      'Documentos',
    ]

    return separador[value];
  }

}