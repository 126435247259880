import { Component, Input, ViewChild, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AsYouType, CountryCode, parsePhoneNumber, getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { MatSelect } from '@angular/material/select';
import countryNames from './country-names';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements OnInit, OnChanges {
  codeCountry!: any;

  selectedCodeCountry: number = 351;
  selectedCode: string | null = 'PT';
  formattedPhone!: any;
  openSelector: boolean = false;
  sortedNames: any;
  countryNames: { [key: string]: any } = countryNames;
  iForm!: FormGroup;
  countrySorted!: any;
  isPhoneInputDirty: boolean = false;
  phoneInput$: any;

  @ViewChild('phoneInputCountry') countrySelector!: MatSelect;
  @ViewChild('phoneInputView') viewInput!: any;

  @Input() form!: FormGroup;
  @Input() field: string = 'phoneNumber';

  constructor(private fb: FormBuilder) {
    this.codeCountry = getCountries();
    this.iForm = this.fb.group({
      country: 'PT',
      code: 351,
      formattedPhone: '',
    });
  }

  ngOnInit(): void {
    const result = Object.entries(countryNames);
    const countrySorted = result.sort((a, b) => a[1].localeCompare(b[1]));
    const myObject = [...countrySorted];
    this.countrySorted = myObject;
    const objeto: any = {};

    countrySorted.forEach(item => {
      const chave = item[0];
      const valor = item[1];
      objeto[chave] = valor;
    });

    // cria uma subcription no phone input para atualizar sempre que é alterado
    this.phoneInput$ = this.form.get(this.field)?.valueChanges.subscribe({
      next: value => {
        this.refreshInitialPhone(value);
      },
    });
  }

  ngOnChanges(): void {
    this.refreshInitialPhone(this.form.get(this.field)?.value);
  }

  resetPhoneInput(): void {
    this.iForm.patchValue({
      country: 'PT',
      code: 351,
      formattedPhone: '',
    });
    this.setCodeNumber('PT');
  }

  refreshInitialPhone(value: string) {
    if (value) {
      try {
        let parsed = parsePhoneNumber(value);
        this.iForm.get('country')?.patchValue(parsed?.country);
        this.iForm.get('code')?.patchValue(parsed?.countryCallingCode);
        let code: CountryCode = <CountryCode>parsed?.country;
        this.setCodeNumber(code);
        let iValue = new AsYouType(code).input(parsed?.nationalNumber);
        this.iForm.get('formattedPhone')?.patchValue(iValue);
      } catch (e) {
        console.log('Format Phone number error: ' + e);
      }
    } else {
      this.resetPhoneInput();
    }

    this.iForm.updateValueAndValidity();
  }

  open() {
    this.openSelector = true;
    this.countrySelector.open();
  }

  setCodeNumber(code: any) {
    this.selectedCode = code;
    this.selectedCodeCountry = Number(code);
  }

  formatPhone() {
    let code: CountryCode = <CountryCode>this.selectedCode;
    let iField = this.iForm.get('formattedPhone');
    let eField = this.form.get(this.field);
    let hasError: boolean | null = null;

    if (!iField) {
      return console.log('phone-input-component', 'internal form field not found');
    }

    if (!eField) {
      return console.log('phone-input-component', 'external field not found');
    }

    this.formattedPhone = new AsYouType(code).input(iField.value);
    let nCode = getCountryCallingCode(code);

    iField.patchValue(this.formattedPhone);
    let nNumber = this.formattedPhone
      .split(/[^0-9]/)
      .join('')
      .trim();

    if (this.selectedCode && nNumber) {
      eField.patchValue(`+${nCode} ${nNumber}`);
      eField.markAsDirty();

      try {
        let number = parsePhoneNumber(nNumber, code);
        hasError = number.isValid() ? null : true;
      } catch (e: any) {
        if (e.message == 'TOO_LONG' || e.message == 'TOO_SHORT') {
          hasError = true;
        }
      }

      if (hasError) {
        eField.setErrors({ pattern: hasError });
        iField.setErrors({ pattern: hasError });
        iField.markAsUntouched();
      }
    } else {
      eField.patchValue('');
      eField.markAsDirty();
    }
  }
}
