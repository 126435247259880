import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-numered-item',
  templateUrl: './numered-item.component.html',
  styleUrls: ['./numered-item.component.scss'],
})
export class NumeredItemComponent {
  @Input() number!: number;
  @Input() title!: string;
  @Input() smallerTitle: string = '';
  @Input() isLast = false;
  @Input() dotted = false;
  @Input() startOpen = false;
  @Input() isRequired = false;
  @Input() numberedListParentId = '#numbered-list-123'; // tem de preencher isto para o collapse funcionar corretamente com o mesmo id do parent app-numbered-list - has to start with HASHTAG # !!! - its id selector
  @Input() subtitle!: string;
}
