import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-numered-list',
  templateUrl: './numered-list.component.html',
  styleUrls: ['./numered-list.component.scss']
})

export class NumeredListComponent {
  @Input() idListName = 'numbered-list-123'; // give a class name to this list - makes the animation and close-all work
}
