import { ILinks } from "src/app/shared/interfaces/link.interface";

export const auxiliarLinks: Array<ILinks> = [
  {
    path: '/',
    title: 'Home',
  },
  {
    path: '/dados-financeiros',
    title: 'Dados Financeiros',
  },
  {
    path: '/pagamentos',
    title: 'Pagamentos',
  },
  {
    path: '/documentos',
    title: 'Documentos',
  }
]
