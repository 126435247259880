import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumeredListComponent } from './numered-list.component';
import { NumeredItemComponent } from './numered-item/numered-item.component';

@NgModule({
  declarations: [
    NumeredListComponent,
    NumeredItemComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NumeredListComponent,
    NumeredItemComponent
  ],
})
export class NumeredListModule { }
