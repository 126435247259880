export default {
  AC: 'Santa Helena',
  AX: 'Ilhas Åland',
  SJ: 'Svalbard e Jan Mayen',
  TA: 'Tristão da Cunha',
  AD: 'Andorra',
  AE: 'Emirados árabes Unidos',
  AF: 'Afeganistão',
  AG: 'Antígua e Barbuda',
  AI: 'Anguilla',
  AL: 'Albânia',
  AM: 'Arménia',
  AO: 'Angola',
  AQ: 'Antártida',
  AR: 'Argentina',
  AS: 'Samoa Americana',
  AT: 'Austria',
  AU: 'Austrália',
  AW: 'Aruba',
  AZ: 'Azerbaijão',
  BA: 'Bósnia e Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Bélgica',
  BF: 'Burkina Faso',
  BG: 'Bulgária',
  BH: 'Bahrein',
  BI: 'Burundi',
  BJ: 'Benim',
  BL: 'São Bartolomeu',
  BM: 'Bermudas',
  BN: 'Brunei',
  BO: 'Bolívia',
  BQ: 'Países Baixos Caribenhos',
  BR: 'Brasil',
  BS: 'Bahamas',
  BT: 'Butão',
  BW: 'Botswana',
  BY: 'Bielorrússia',
  BZ: 'Belize',
  CA: 'Canadá',
  CC: 'Ilhas Cocos',
  CD: 'República Democrática do Congo',
  CF: 'República Centro-áfricana',
  CG: 'República do Congo',
  CH: 'Suíça',
  CI: 'Costa do Marfim',
  CK: 'Ilhas Cook',
  CL: 'Chile',
  CM: 'Camarões',
  CN: 'China',
  CO: 'Colômbia',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cabo Verde',
  CW: 'Curaçao',
  CX: 'Ilha Christmas',
  CY: 'Chipre',
  CZ: 'Tchéquia',
  DE: 'Alemanha',
  DJ: 'Djibouti',
  DK: 'Dinamarca',
  DM: 'Dominique',
  DO: 'República Dominicana',
  DZ: 'Argélia',
  EC: 'Equador',
  EE: 'Estónia',
  EG: 'Egitoe',
  EH: 'República Democrática árabe Saariana',
  ER: 'Eritreia',
  ES: 'Espanha',
  ET: 'Etiópia',
  FI: 'Finlândia',
  FJ: 'Fiji',
  FK: 'Ilhas Malvinas',
  FM: 'Micronésia',
  FO: 'Ilhas Feroé',
  FR: 'França',
  GA: 'Gabão',
  GB: 'Reino Unido',
  GD: 'Grenada',
  GE: 'Geórgia',
  GF: 'Guiana Francesa',
  GG: 'Guernsey',
  GH: 'Gana',
  GI: 'Gibraltar',
  GL: 'Groelândia',
  GM: 'Gâmbia',
  GN: 'Guiné',
  GP: 'Guadalupe',
  GQ: 'Guiné Equatorial',
  GR: 'Grécia',
  GS: 'Ilhas Geórgia e Sandwich do Sul',
  GU: 'Guam',
  GT: 'Guatemala',
  GW: 'Guiné-Bissau',
  GY: 'Guiana',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HR: 'Croácia',
  HT: 'Haití',
  HU: 'Hungria',
  ID: 'Indonesia',
  IE: 'Irlanda',
  IL: 'Israel',
  IM: 'Ilha de Man',
  IN: 'índia',
  IO: 'Território Britânico do Oceano índico',
  IQ: 'Iraque',
  IR: 'Irão',
  IS: 'Islândia',
  IT: 'Itália',
  JE: 'Jersey',
  JM: 'Jamaïca',
  JO: 'Jordânia',
  JP: 'Japão',
  KE: 'Quênia',
  KG: 'Quirguistão',
  KH: 'Camboja',
  KI: 'Kiribati',
  KM: 'Comores',
  KN: 'São Cristóvão e Nevis',
  KP: 'Coreia do Norte',
  KR: 'Coreia do Sul',
  KW: 'Kuwait',
  KY: 'Ilhas Cayman',
  KZ: 'Cazaquistão',
  LA: 'Laos',
  LB: 'Líbano',
  LC: 'Santa Lúcia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Libéria',
  LS: 'Lesoto',
  LT: 'Lituânia',
  LU: 'Luxemburgo',
  LV: 'Letónia',
  LY: 'Líbia',
  MA: 'Marrocos',
  MC: 'Monaco',
  MD: 'Moldávia',
  ME: 'Montenegro',
  MF: 'São Martinho',
  MG: 'Madagáscar',
  MH: 'Ilhas Marshall',
  MK: 'Macedónia',
  ML: 'Mali',
  MM: 'Mianmar',
  MN: 'Mongólia',
  MO: 'Macau',
  MP: 'Ilhas Marianas Setentrionais',
  MQ: 'Martinica',
  MR: 'Mauritanie',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Maurícia',
  MV: 'Maldivas',
  MW: 'Malawi',
  MY: 'Malásia',
  MX: 'México',
  MZ: 'Moçambique',
  NA: 'Namíbia',
  NC: 'Nova Caledónia',
  NE: 'Níger',
  NF: 'Ilha Norfolk',
  NG: 'Nígeria',
  NI: 'Nicarágua',
  NL: 'Países Baixos',
  NO: 'Noruega',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'Nova Zelândia',
  OM: 'Omã',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'Polinésia Francesa',
  PG: 'Papua-Nova Guiné',
  PH: 'Filipinas',
  PK: 'Paquistão',
  PL: 'Polónia',
  PM: 'São Pedro e Miquelão',
  PN: 'Ilhas Pitcairn',
  PR: 'Puerto Rico',
  PS: 'Palestina',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguai',
  QA: 'Catar',
  RE: 'Reunião',
  RO: 'Roménia',
  RS: 'Sérvia',
  RU: 'Rússia',
  RW: 'Ruanda',
  SB: 'Ilhas Salomão',
  SH: 'Santa Helena, Ascensão e Tristão da Cunha',
  SA: 'Arábia Saudita',
  SC: 'Seicheles',
  SD: 'Sudão',
  SE: 'Suécia',
  SG: 'Singapura',
  SI: 'Eslovênia',
  SK: 'Eslováquia',
  SL: 'Serra Leoa',
  SM: 'San Marino',
  SN: 'Senega',
  SO: 'Somália',
  SR: 'Suriname',
  SS: 'Sudão do Sul',
  ST: 'São Tomé e Príncipe',
  SV: 'El Salvador',
  SX: 'Sint-Maarten',
  SY: 'Síria',
  SZ: 'Eswatani',
  TC: 'Ilhas Turcas e Caicos',
  TD: 'Chade',
  TF: 'Terras Austrais e Antárticas Francesas',
  TG: 'Togo',
  TH: 'Tailândia',
  TJ: 'Tajiquistão',
  TK: 'Tokelau',
  TL: 'Timor-Leste',
  TM: 'Turquemenistão',
  TN: 'Tunísia',
  TO: 'Tonga',
  TR: 'Turquía',
  TT: 'Trinidad e Tobago',
  TW: 'Taiwan',
  TV: 'Tuvalu',
  TZ: 'Tanzânia',
  UA: 'Ucrânia',
  UG: 'Uganda',
  US: 'Estados Unidos',
  UY: 'Uruguai',
  UZ: 'Uzbequistão',
  VA: 'Vaticano',
  VC: 'São Vicente e Granadinas',
  VE: 'Venezuela',
  VG: 'Ilhas Virgens Britânicas',
  VN: 'Vietname',
  VU: 'Vanuatu',
  VI: 'Ilhas Virgens dos Estados Unidos',
  WF: 'Wallis e Futuna',
  WS: 'Samoa',
  XK: 'Kosovo',
  YE: 'Iémen',
  YT: 'Maiote',
  ZA: 'África do Sul',
  ZM: 'Zâmbia',
  ZW: 'Zimbabwe',
};
