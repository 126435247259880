import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent} from '@angular/router';
import { UserService } from './shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  userType = this.userService.getUserRole();
  isPublic = true;

  hasHeader = true;
  hasPadding = true;

  isProduction = environment.enviromentName === 'PROD';



  userName = this.userService.getUserName();

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => this.modifyHeader(event as RouterEvent));
  }

  modifyHeader({url}: RouterEvent) {
    if(!url) return;
    
    this.userType = this.userService.getUserRole()
    this.userName = this.userService.getUserName()
    
    const routesWithoutHeader = [
      'login',
      'esqueci-palavra-chave',
      'validar-email',
      'trocar-palavra-chave',
      'authorized'
    ];

    this.hasHeader = !(routesWithoutHeader.some((route) => url.includes(route)));
    
    const routesWithoutPadding = [
      '/'
    ];
    this.hasPadding = !(routesWithoutPadding.some((route) => url === route));
  }
}
