import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'AreaArtistica'
})
export class AreaArtisticaPipe implements PipeTransform {

  transform(value: number): string {
    const areaArtistica = [
      'Artes de Rua',
      'Circo',
      'Dança',
      'Música',
      'Ópera',
      'Teatro',
      'Arquitetura',
      'Artes Plásticas',
      'Design',
      'Fotografia',
      'Novos Media',
      'Cruzamento Disciplinar',
      'Artes Digitais'
    ]

    return areaArtistica[+value - 1];
  }
}
