import { Pipe, PipeTransform } from '@angular/core';

// made by E. Correia
// this very advanced pipe takes in an array of searcheable elements, which can also be an array of complex objects, takes in a search term and returns an
// array with all the elements/objects that contain at least one key that corresponds to the search term.
// can be easily used in mat-select boxes where you use the ng-for on the option list and do "| filterSearch: <insert here the input field that will be used as the search input for the pipe>"

// example:

// <input type="text" formControlName="searchField"/>
// <mat-select>
//   <mat-option *ngFor="let item of arrayItems | filterSearch: form.controls.searchField?.value">
//     some option
//   </mat-option>
// </mat-select>

@Pipe({
  name: 'filterSearch'
})

export class FilterSearchPipe implements PipeTransform {
  transform(originalItemsArray: any[], searchByText?: string, searchByArray?: any[]): any {
    if (!originalItemsArray) {
      return [];
    } else if (!searchByText) {
      return originalItemsArray;
    } else if (searchByText) {
      const searchTerms = searchByText.toString().toLocaleLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, ""); // replaces any accented characters (diacritics) with normalized ones then replaces diacritics with empty - used to consider á === a in search terms.
      return originalItemsArray.filter( data => {
        return Object.values(data).toString().toLocaleLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "").includes(searchTerms);
      });
    } else if (searchByArray && searchByArray.length > 0) {
      const searchTermsArray = searchByArray.map( itemArray => itemArray.toString().toLocaleLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "") );
      return originalItemsArray.filter( data => {
        return searchTermsArray.every( searchTerm => {
          return Object.values(data).toString().toLocaleLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "").includes(searchTerm);
        });

      });
    } else {
      return originalItemsArray;
    }
  }
}