import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ILinks } from 'src/app/shared/interfaces/link.interface';

@Component({
  selector: 'app-header-link',
  templateUrl: './header-link.component.html',
  styleUrls: ['./header-link.component.scss'],
})
export class HeaderLinkComponent implements OnChanges {
  @Input() links!: Array<ILinks>;

  @Input() isMenuOpen!: boolean;
  @Output() setIsMenuOpen = new EventEmitter<boolean>();

  constructor(private router: Router) {
  }
  ngOnChanges(): void {
    if (this.isMenuOpen) {
      const logoutButton = document.getElementById('logout-button-menu');
      if (logoutButton) {
        logoutButton.style.display = 'none';
      }
      this.setMenuLinksAnimation();
    }
  }

  navigate(linkObject: ILinks) {
    if (linkObject?.greyedOutBoolean) {
      // link is disabled, dont do anything
      return;
    } else {
      this.router.navigate([linkObject.path]);
      this.setIsMenuOpen.emit(!this.isMenuOpen);
    }
  }

  setMenuLinksAnimation(): void {
    let lastButtonAnimationDelayMilisec = 0;

    Object.entries(this.links).forEach((entry: any) => {
      this.links[entry[0]].show = false;
      setTimeout(() => {
        this.links[entry[0]].show = true;

        if (this.links[entry[0]].title === this.links[this.links.length - 1].title) {
          setTimeout(() => {
            const logoutButton = document.getElementById('logout-button-menu');
            if (logoutButton) {
              logoutButton.style.display = 'inline-block';
            }
          }, 70);
        }
      }, lastButtonAnimationDelayMilisec);
      lastButtonAnimationDelayMilisec = lastButtonAnimationDelayMilisec + 70;
    });
  }
}
