import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Dialog } from '@angular/cdk/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
  constructor(private router: Router, private service: AuthService, private userService: UserService, private dialog: Dialog) {}

  private getPermissionBoolean(activatedRoute: string): boolean {
    let permission = this.userService.getPermissionUserForRequestedRoute(activatedRoute);
    if (permission === 'read' || permission === 'readWrite') {
      // tem read OU readWrite permission (o proprio componente vai depois verificar qual, para aqui basta uma)
      return true;
    } else {
      // no permission
      this.dialog.open(DialogComponent, { data: { type: 'custom-error', message: ['O seu utilizador atual não parece ter permissões para aceder a este componente ou as mesmas ainda não lhe foram atribuídas.'] } });
      this.router.navigate(['home']);
      return false;
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.service.isLoggedIn()) {
      let activatedRoute = state.url;
      if (activatedRoute && activatedRoute !== '/') {
        if (activatedRoute === '/home' && this.userService.isAuxiliar) {
          // user auxiliar nao pode aceder a esta home, apenas a /
          this.router.navigate(['']);
          return false;
        } else if (activatedRoute !== '/home') {
          if (this.userService.currentlySelectedEntidadeRecHumanos) { // user que está a usar um perfil dos recursos humanos
            if (this.userService?.permissoesEntidadeColetivaSelectionada?.length > 0) {
              return this.getPermissionBoolean(activatedRoute);
            } else {
              return new Promise<boolean>((resolve, reject) => {
                // ainda nao temos permissoes (user abriu URL direto), esperar que elas cheguem
                this.userService.userPermissionsAreDoneObservableBoolean.subscribe((result) => {
                  resolve(this.getPermissionBoolean(activatedRoute));
                });
              });
            }
          } else {
            // nao é individual nem auxiliar, pode entrar nas rotas
            return true;
          }
        } else {
          return true; // deixa entrar na /home
        }
        
      } else return true;
    } else {
      localStorage.clear();
      this.router.navigate(['login'], { replaceUrl: true });
      return false;
    }
  }
}
