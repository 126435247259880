import { Component, Input } from '@angular/core';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-collapse-faq',
  templateUrl: './collapse-faq.component.html',
  styleUrls: ['./collapse-faq.component.scss'],
})
export class CollapseFaqComponent {
  @Input() id!: number
  @Input() pergunta!: string
  @Input() resposta!: string

  isCollapseOpen = false;
  faPlus = faPlus;
  faMinus = faMinus;
}
