<nav class="d-flex align-items-center justify-content-between">
  <ul class="d-flex align-items-center p-0 m-0">
    <li>
      <a [routerLink]="userLogged ? '/home' : '/'"><fa-icon [icon]="faHouse" /></a>
    </li>
    <li *ngFor="let breadcrumbItem of breadcrumbList">
      <a [routerLink]="!breadcrumbItem?.disableClick ? [breadcrumbItem.path] : undefined">
        <fa-icon [icon]="faAngleRight" class="px-2" />
        {{ breadcrumbItem.title }}
      </a>
    </li>
  </ul>
  <a (click)="ShowState()" class="helpButton" *ngIf="helpButton">Precisa de ajuda?</a>
</nav>
<div style="display: flex; justify-content: end; color: gray" *ngIf="existe">
  <mat-icon style="font-size: 19px; vertical-align: text-top">info</mat-icon><span>Dados a disponibilizar brevemente.</span>
</div>
