<div class="animate-slide-right" style="animation-delay: 0.2s; opacity: 0; animation-duration: 0.5s; position: sticky; top: 90px">
  <ul *ngFor="let menuItem of menuList" class="p-0 m-0">
    <li *ngIf="menuItem.show" class="my-2 animate-slide-right" (click)="openChildren(menuItem.title, !!menuItem.disabled)" [ngClass]="menuItem.title.replaceAll(' ', '')">
      <button
        mat-flat-button
        color="primary"
        type="button"
        class="justify-content-start p-4"
        routerLinkActive="menu-active"
        [ngClass]="{ 'menu-active': menuItem?.routerActive, 'no-children': !menuItem.child.length }"
        [routerLink]="menuItem.route.length > 0 ? menuItem.route : undefined"
        [disabled]="!!menuItem.disabled"
        (click)="clickedMenuItem(menuItem)">
        {{ menuItem.title }}
      </button>
      <ul *ngFor="let menuChildItem of menuItem.child" class="p-0">
        <li class="my-2 d-none slide-in-top child" [ngClass]="menuItem.title.replaceAll(' ', '')">
          <button
            mat-stroked-button
            color="primary"
            type="button"
            class="subtitles justify-content-start p-4"
            routerLinkActive="menu-active"
            [routerLink]="menuChildItem.route"
            [disabled]="!!menuItem.disabled">
            {{ menuChildItem.title }}
          </button>
        </li>
      </ul>
    </li>
  </ul>
</div>
