import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { ColectiveContact } from 'src/app/shared/interfaces/colective-contact';
import { ResponseDefault } from 'src/app/shared/interfaces/response-default.interface';
import { UserExternal, UserInternal } from 'src/app/shared/interfaces/user-external.model';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public static ROLE_INDIVIDUAL = 'ExternalIndividual';
  public static ROLE_COLETIVO = 'ExternalGroup';
  public static ROLE_AUXILIAR = 'UserAuxiliar';
  public static ROLE_INTERNO = 'InternalUser';
  public currentlySelectedEntidadeRecHumanos: any; // user selecionado no dropdown do individual

  constructor(private http: HttpClient) {}

  private baseUrl = `${environment.apiUrl}/user`;

  private originalIndividualUserToken = '';
  private originalIndividualUserId = '';
  private selectedEntidadeColetivaToken = '';
  private selectedEntidadeColetivaId = '';
  permissoesEntidadeColetivaSelectionada = new Array();
  userPermissionsAreDoneObservableBoolean = new Subject(); // apenas da next quando as permissoes chegarem do server - usado no route guard Authorization


  private permissionsToRoutesDictionary = {
    // route <=> permission
    eregistoIdentificacaoEntidade: '/e-registo/identificacao-da-entidade',
    eregistoLocalidade: '/e-registo/localizacao',
    eregistoContactos: '/e-registo/contactos',
    eregistoAtividades: '/e-registo/atividades',
    eregistoRecursosHumanos: '/e-registo/recursos-humanos',
    eregistoDadosFinanceiros: '/e-registo/dados-financeiros',
    eregistoEquipamentosEspacos: '/e-registo/equipamentos-e-espacos',
    eregistoDocumentos: '/e-registo/documentos',
    eregistoPagamentos: '/e-registo/pagamentos',
    eregistoDefinicoesConta: '/e-registo/definicoes-de-conta',
    rtcpPedidosRealizados: '/rtcp',
    rtcpEquipamentosCredenciar: '/rtcp/pedido-de-credenciacao',
    rtcpAudienciaInteressados: '/rtcp/audiencia-de-interessados',
    rpacPedidosRealizados: '/rpac',
    rpacEquipamentosCredenciar: '/rpac',
    rpacAudienciaInteressados: '/rpac',
    paConcursosDecorrer: '/programas-de-apoio/concursos-a-decorrer',
    paMinhasCandidaturas: '/programas-de-apoio/minhas-candidaturas',
    paAudienciaInteressados: '', // nao existe ainda ?
    execucaoPlanoAtividades: '', // nao existe ainda ?
    execucaoRelatorio: '', // nao existe ainda ?
    execucaoOrcamento: '', // nao existe ainda ?
    execucaoRecursosHhumanos: '', // nao existe ainda ?
    execucaoAgenda: '', // nao existe ainda ?
    bolsaConsultoresEspecialistas: '/bolsa-consultores-especialistas',
  };

  postUserExternal(userExternal: UserExternal): Observable<null> {
    const url = `${this.baseUrl}/external`;
    return this.http.post<null>(url, userExternal);
  }

  getInternalUsers(): Observable<ResponseDefault<UserInternal[]>> {
    const url = `${this.baseUrl}/internal`;
    return this.http.get<ResponseDefault<UserInternal[]>>(url);
  }

  getContactsByExternalUser(id: string): Observable<ResponseDefault<any[]>> {
    const url = `${this.baseUrl}/external/contatos?ExternalUserId=${id}`;
    return this.http.get<ResponseDefault<any[]>>(url);
  }

  getOutrosContatos(externaUserId: string): Observable<ResponseDefault<ColectiveContact[]>> {
    const url = `${this.baseUrl}/external/outros-contatos?ExternalUserId=${externaUserId}`;
    return this.http.get<ResponseDefault<ColectiveContact[]>>(url);
  }

  postContacts(otherContacts: ColectiveContact, userId: string) {
    const url = `${this.baseUrl}/external/outros-contatos`;
    const newColectiveContact = {
      nome: otherContacts.nome,
      contatoTelefonico: otherContacts.contatoTelefonico,
      email: otherContacts.email,
      areaResponsabilidadeId: otherContacts.areaResponsabilidadeId,
      notaBiografica: otherContacts.notaBiografica,
      externalUserId: userId,
    };
    return this.http.post<ResponseDefault<number>>(url, newColectiveContact);
  }

  putContacts(otherContacts: ColectiveContact, userId: string, id: string) {
    const url = `${this.baseUrl}/external/outros-contatos/${id}`;

    const newColectiveContact = {
      nome: otherContacts.nome,
      contatoTelefonico: otherContacts.contatoTelefonico,
      email: otherContacts.email,
      notaBiografica: otherContacts.notaBiografica,
      areaResponsabilidadeId: otherContacts.areaResponsabilidadeId,
      externalUserId: userId,
    };

    return this.http.put<any>(url, newColectiveContact);
  }

  deleteContacts(id: string) {
    const url = `${this.baseUrl}/external/outros-contatos/${id}`;
    return this.http.delete<any>(url);
  }

  getDadosProfissionais(id: string): Observable<any> {
    const url = `${this.baseUrl}/external/dados-profissionais?ExternalUserId=${id}`;
    return this.http.get<any>(url);
  }

  postDadosProfissionais(userId: string, body: any): Observable<any> {
    const url = `${this.baseUrl}/external/dados-profissionais`;
    const newDadosProfissionais = {
      nomeProfissional: body.nome,
      email: body.email,
      contactoTelefonico: body.contactoTelefonico,
      paginaInternet: body.paginaInternet,
      notaBiografica: body.notaBiografica,
      externalUserId: userId,
    };
    return this.http.post<any>(url, newDadosProfissionais);
  }

  putDadosProfissionais(userId: string, id: number, body: any): Observable<any> {
    const url = `${this.baseUrl}/external/dados-profissionais/${id}`;
    const newDadosProfissionais = {
      nomeProfissional: body.nome,
      email: body.email,
      contactoTelefonico: body.contactoTelefonico,
      paginaInternet: body.paginaInternet,
      notaBiografica: body.notaBiografica,
      externalUserId: userId,
    };
    return this.http.put<any>(url, newDadosProfissionais);
  }

  getMe(): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/external/me`;
    return this.http.get<ResponseDefault<any>>(url);
  }

  getDadosProfissionaisByNumeroRegistro(numeroRegistro: string): Observable<any> {
    const url = `${this.baseUrl}/external/dados-profissionais-n-registro?NumeroRegistro=${numeroRegistro}`;
    return this.http.get<any>(url).pipe(map((res: any) => res.data));
  }

  getDocumentoDownload(nomeDocumento: string) {
    const url = `${this.baseUrl}/documento/download?nomeDocumento=${nomeDocumento}`;
    const token = localStorage.getItem('userToken');
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get(url, { responseType: 'blob', headers });
  }

  getLocalUserData() {
    let lsUser = JSON.parse(localStorage.getItem('user') || '{}');
    if (!lsUser) return {};
    if (!lsUser.user) return {};
    return lsUser.user;
  }

  getUserTokenInfo() {
    let userToken = localStorage.getItem('userToken'); // esta token nunca ira alterar sera sempre o original
    if (userToken) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(userToken);
      return decodedToken;
    }
    return;
  }

  getUserRole() {
    let jwtData = this.getUserTokenInfo();
    if (!jwtData) return false;
    return jwtData.role;
  }

  getUserNameFromToken() {
    let jwtData = this.getUserTokenInfo();
    if (!jwtData) return false;
    return jwtData.unique_name;
  }

  getUserName() {
    let userData = this.getLocalUserData();
    if (!userData) return false;
    return userData.nomeComum ? userData.nomeComum : this.getUserNameFromToken();
  }

  getRawUserClaims() {
    let jwtData = this.getUserTokenInfo();
    if (!jwtData) return false;
    return jwtData.claims || [];
  }

  getUserClaims() {
    let claims = this.getRawUserClaims();

    if (environment.enviromentName === 'DEV') {
      let disabledClaims = JSON.parse(localStorage.getItem('disabledClaims') || '[]');
      claims = claims.filter((item: string) => disabledClaims.indexOf(item) === -1);
    }

    return claims;
  }

  is(role: string) {
    return this.getUserClaims().indexOf(role) !== -1;
  }

  can(role: string) {
    return this.is(role);
  }

  get isColetivo() {
    if (this.currentlySelectedEntidadeRecHumanos) {
      // disfarça-se de coletivo, da true
      return true;
    } else {
      return this.getUserRole() === UserService.ROLE_COLETIVO;
    }
  }

  get isIndividual() {
    if (this.currentlySelectedEntidadeRecHumanos) {
      // disfarça-se de coletivo, da false neste
      return false;
    } else {
      return this.getUserRole() === UserService.ROLE_INDIVIDUAL;
    }
  }

  get isAuxiliar() {
    return this.getUserRole() === UserService.ROLE_AUXILIAR;
  }

  get isInterno() {
    return this.getUserRole() === UserService.ROLE_INTERNO;
  }

  getPermissionUserForRequestedRoute(route: string): string | null {
    // returns null (value, not string) for no access, 'read' for read permission, 'readWrite' for full permission
    // esta funcao tambem é usada no eregisto TS para filtrar os itens de menu que sao apresentados
    if (this.currentlySelectedEntidadeRecHumanos) {
      if (this.permissoesEntidadeColetivaSelectionada?.length > 0 && route) {
        let routePermissionTag = '';
        for (const [permissionTag, routeDictionary] of Object.entries(this.permissionsToRoutesDictionary)) {
          if (route.startsWith(routeDictionary)) {
            routePermissionTag = permissionTag;
            break;
          }
        }
        if (routePermissionTag) {
          let permissionType = this.permissoesEntidadeColetivaSelectionada.find(permission => permission.id === routePermissionTag)?.tipo ?? null;
          if (permissionType === 'null') {
            permissionType = null;
          }
          return permissionType;
        } else {
          return null; // se permissao se nao encontrou a rota nem a permissionTag no array de permissions desta entidade
        }
      } else {
        // se nao tem array de permissoes, não deixar o user aceder a nada desta entidade
        return null;
      }
    } else {
      // se nao é user a entrar com outra entidade, deixar aceder a tudo (porque esta a consultar coisas dele proprio)
      return 'readWrite';
    }
  }

  setOriginalUserTokenAndId(newToken: string, newId: string): void {
    this.originalIndividualUserToken = newToken;
    this.originalIndividualUserId = newId;
  }

  setEntidadeColetivaUserTokenAndId(newToken: string, newId: string): void {
    this.selectedEntidadeColetivaToken = newToken;
    this.selectedEntidadeColetivaId = newId;
  }

  useOriginalUserTokenAndIdAndClear(): void {
    // coloca o token original do user individual na localstorage dando reset
    localStorage.setItem('token', this.originalIndividualUserToken);
    localStorage.setItem('userId', this.originalIndividualUserId);
    this.clearPermissionsArray();
    this.clearEntidadeFromRecHumanos();
  }

  useEntidadeColetivaSelectedUserTokenAndId(): void {
    // coloca o token da entidade selecionada para uso nos requests
    localStorage.setItem('token', this.selectedEntidadeColetivaToken);
    localStorage.setItem('userId', this.selectedEntidadeColetivaId)
  }

  setRoutesPermissionsArray(newPermsArray: any[]): void {
    this.permissoesEntidadeColetivaSelectionada = newPermsArray;

    // emitir este subject para o authorization route guard processar o pedido
    this.userPermissionsAreDoneObservableBoolean.next(true);
  }

  clearPermissionsArray(): void {
    this.permissoesEntidadeColetivaSelectionada = [];
  }

  selectNewEntidadeFromRecHumanos(newUserSelected: any): void {
    this.currentlySelectedEntidadeRecHumanos = newUserSelected;
    this.setEntidadeColetivaUserTokenAndId(newUserSelected?.token ?? '', newUserSelected?.id ?? '');
    this.useEntidadeColetivaSelectedUserTokenAndId();
  }

  clearEntidadeFromRecHumanos(): void {
    this.currentlySelectedEntidadeRecHumanos = undefined;
  }

  getSGI(numeroSgi:string): Observable<any> {
    const url = `${this.baseUrl}/${numeroSgi}/utilizadores-ativos-bce`;
    return this.http.get<any>(url);
  }
}
