import { ILinks } from "src/app/shared/interfaces/link.interface";

export const internalLinks: Array<ILinks> = [
  {
    path: '/interno/gestao-utilizador',
    title: 'Gestão de perfis de utilizador interno',
  },
  {
    path: '/interno/rtcp',
    title: 'RTCP',
    // tooltip: 'Disponível brevemente',
    // greyedOutBoolean: true
  },
  {
    path: '/interno/rpac',
    title: 'RPAC',
    // tooltip: 'Disponível brevemente',
    // greyedOutBoolean: true
  },
  {
    path: '/interno/acao-externa-cultural',
    title: 'Ação cultural externa',
  },
  {
    path: '/interno/bolsa-consultores-especialistas/pedidos',
    title: 'Bolsa de consultores e especialistas',
  },
  {
    path: '/interno/programas-de-apoio',
    title: 'Programas de Apoio',
    // tooltip: 'Disponível brevemente',
  },
  {
    path: '/interno/formularios',
    title: 'Formulários',
    // tooltip: 'Disponível brevemente',
    // greyedOutBoolean: true
  },
  {
    path: '/interno/configuracoes',
    title: 'Configurações',
  },
  {
    path: 'avaliacao-concursos',
    title: 'Avaliação de Concursos',
    tooltip: 'Disponível brevemente',
  },
]
