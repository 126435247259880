import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortAlphabetically'
})
export class SortAlphabeticallyPipe  implements PipeTransform {
    transform(arrayToBeSorted: any, objectKeyToSort: string, invert?: boolean, sortingDates?: boolean): any[] {
        if (!arrayToBeSorted) {
            return arrayToBeSorted;
        }
        arrayToBeSorted.sort((a: any, b: any) => {
            let sortResult = 0;
            if (!sortingDates) {
                if (typeof a[objectKeyToSort] === 'boolean') {
                    sortResult = a[objectKeyToSort] ? -1 : 1; // true ficam a frente dos false no array
                } else {
                    // novo metodo moderno para comparar strings e/ou numbers de acordo com o locale (neste caso pt)
                    sortResult = (a[objectKeyToSort] || '').toLowerCase().localeCompare((b[objectKeyToSort] || '').toLowerCase(), 'pt', { ignorePunctuation: false, numeric: true });
                }
            } else {
                sortResult = new Date(a[objectKeyToSort] || 0).getTime() > new Date(b[objectKeyToSort] || 0).getTime() ? -1 : 1; // data mais nova primeiro
            }
            if (invert) {
                return -sortResult;
            } else {
                return sortResult;
            }
        });
        return arrayToBeSorted;
    }
}