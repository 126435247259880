import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'estadoEquipamentoEspaco'})
export class EstadoEquipamentoEspacoPipe implements PipeTransform {
  transform(value: number): string {

    const estadosEquipamento = [
    
    'Em Preenchimento',
    'Eliminado',
    'Submetido',
    'Admitido',
    'Admitido Condicionalmente',
    'Recusado',
    'Apreciação Favorável',
    'Apreciação Não Favorável',
    'Decisão Favorável',
    'Decisão Favorável Condicionada',
    'Decisão Não Favorável',
    'Apreciação Favorável Condicionada'
    ]

    return estadosEquipamento[value];
  }
}