import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estadoEquipa'
})
export class EstadoEquipaPipe implements PipeTransform {

  transform(value: number): string {
    const estadoEquipa = [
      'Aceite',
      'Terminado',
      'Pendente'
    ]

    return estadoEquipa[value];
  }

}
