<div class="profile-selector {{isOpen? 'is-open' : ''}}" >
	<div class="profile-selector_header" (click)="toggle()">
		<fa-icon [icon]="currentIcon" ></fa-icon>
	</div>
	<div class="profile-selector_body">
		<h4 class="mt-3">Editor de Permissões:</h4>
		<div class="profile-selector_list">
			<div class="profile-selector-item" *ngFor="let claim of claims">
				<mat-checkbox 
	                color="primary"
	                [name]="claim"
	                [checked]="disabled.indexOf(claim) == -1"
	                (change)="changed($event)"
	            >
				{{claim}}
		        </mat-checkbox>
			</div>
		</div>
		<button mat-flat-button type="submit" color="primary" class="w-100" (click)="refresh()">
            Atualizar
          </button>
	</div>
</div>