<div *ngIf="data && data.type === 'confirm_action'" class="content">
  <div class="title confirm_action">
    <img src="./assets/imgs/icons/question_baloon.svg" alt="confirm_action" />
  </div>

  <div class="text-content mt-3">
    <p>Tem a certeza que pretende {{ data.confirmText }}?</p>
  </div>

  <div class="d-flex m-4 gap-4">
    <button mat-stroked-button color="primary" (click)="close()">Não</button>
    <button mat-raised-button color="primary" (click)="onConfirmAction('exec')">Sim</button>
  </div>
</div>
<div *ngIf="data && data.type === 'custom-confirm'" class="content">
  <div class="title confirm_action">
    <img src="./assets/imgs/icons/question_baloon.svg" alt="confirm_action" />
  </div>

  <div class="text-content mt-3">
    <p>{{ data.confirmText }}</p>
  </div>

  <div class="d-flex m-4 gap-4">
    <button mat-stroked-button color="primary" (click)="close()">Não</button>
    <button mat-raised-button color="primary" (click)="onConfirmAction('exec')">Sim</button>
  </div>
</div>

<div *ngIf="data.type === 'success'" class="content">
  <div (click)="close()" class="close-icon"><img src="./assets/imgs/icons/cross.svg" alt="X" /></div>

  <div class="title success">
    <img src="./assets/imgs/icons/check.svg" alt="success" />
    <h2>sucesso</h2>
  </div>

  <div class="text-content">
    <p>O seu Registo foi criado com sucesso.</p>
    <p>Consulte o seu e-mail para ativar a conta.</p>
  </div>
</div>
<div *ngIf="data.type === 'custom-success'" class="content">
  <div (click)="close()" class="close-icon"><img src="./assets/imgs/icons/cross.svg" alt="X" /></div>

  <div class="title success">
    <img src="./assets/imgs/icons/check.svg" alt="success" />
    <h2>sucesso</h2>
  </div>

  <div class="text-content">
    <ul *ngIf="data.message" class="p-0">
      <li *ngFor="let message of data.message">{{ message }}</li>
    </ul>
  </div>
</div>

<div *ngIf="data.type === 'loading'" class="content loading-dialog">
  <div class="title d-flex flex-row align-items-center justify-content-center">
    <app-loading></app-loading>
    <h2 style="margin: 0px 0px 0px 15px !important">A carregar...</h2>
  </div>
</div>

<div *ngIf="data.type === 'error'" class="content">
  <div (click)="close()" class="close-icon"><img src="./assets/imgs/icons/cross.svg" alt="X" /></div>

  <div class="title error">
    <img src="./assets/imgs/icons/alert.svg" alt="error" />
    <h2>ocorreu um erro</h2>
  </div>

  <div class="text-content">
    <p>Por favor reveja os campos assinalados.</p>
    <ul *ngIf="data.message" class="p-0">
      <li *ngFor="let message of data.message">{{ message }}</li>
    </ul>
  </div>
</div>

<div *ngIf="data.type === 'custom-error'" class="content">
  <div (click)="close()" class="close-icon"><img src="./assets/imgs/icons/cross.svg" alt="X" /></div>

  <div class="title error">
    <img src="./assets/imgs/icons/alert.svg" alt="error" />
    <h2>ocorreu um erro</h2>
  </div>

  <div class="text-content">
    <ul *ngIf="data.message" class="p-0">
      <li *ngFor="let message of data.message">{{ message }}</li>
    </ul>
  </div>

  <button class="mt-3" mat-stroked-button color="primary" (click)="close()">OK</button>
</div>

<div *ngIf="data.type === 'rgpd'" class="content bigger-width">
  <div (click)="close()" class="close-icon"><img src="./assets/imgs/icons/cross.svg" alt="X" /></div>

  <div class="title title-rgpd">
    <h2 class="title-rgpd">REGISTO DE DADOS NA PLATAFORMA DE GESTÃO DE APOIOS ÀS ARTES</h2>
  </div>

  <div class="height-limit">
    <p>
      De acordo com o Decreto-Lei n.º 103/2017, de 24 de agosto, alterado pelo Decreto-Lei n.º 47/2021, de 11 de junho,
      que estabelece o regime de atribuição de apoios financeiros do Estado às artes,
      e com o disposto no artigo 16.º da Portaria n.º 146/2021, de 13 de julho, que aprova o Regulamento dos Programas
      de Apoio às Artes, a submissão de candidaturas aos concursos dos apoios às artes
      realiza-se em formulário eletrónico.
    </p>
    <p>
      A DGARTES apenas solicita o fornecimento de dados relevantes para efeitos do concurso e para a caracterização
      sociodemográfica do setor artístico, excluindo outro tipo de dados. Em caso de
      atribuição de apoio, podem ser solicitados dados pessoais adicionais, que são necessários para a realização do
      contrato. Os dados pessoais recolhidos poderão ser utilizados de forma anonimizada,
      impossibilitando a identificação dos respetivos titulares, e tratados para fins de arquivo de interesse público,
      fins de investigação científica ou histórica ou fins estatísticos.
    </p>
    <p>
      Em sequência do registo e nos termos da legislação em vigor relativa à proteção de dados pessoais, os interessados
      podem exercer os seus direitos de: i) informação e acesso, retificação ou
      atualização dos dados pessoais, apagamento/esquecimento, anulação do consentimento prestado; ii) opor-se ao
      tratamento quando admissível; iii) receber em formato digital os dados pessoais que
      lhe digam respeito e solicitar, por escrito, a respetiva transmissão diretamente para outro responsável, sempre
      que tal se mostre tecnicamente possível; iv) direito de não ficar sujeito a
      nenhuma decisão tomada exclusivamente com base no tratamento automatizado, mediante comunicação escrita dirigida
      ao encarregado de proteção de dados sito no edifício-sede da Secretaria-Geral da
      Presidência do Conselho de Ministros, sita na Rua Professor Gomes Teixeira, n.º 2, 1399-022 Lisboa, ou, em
      alternativa, para o seguinte endereço de correio eletrónico pro.dados@sg.pcm.gov.pt ou
      apresentar reclamação junto da Autoridade competente - Comissão Nacional de Proteção de Dados (CNPD) - Av. D.
      Carlos I, 134, 1.º, 1200-651 Lisboa - Tel: 213928400 - Fax: 213976832 – e-mail:
      geral@cnpd.pt ou www.cnpd.pt.
    </p>
    <p>
      Mais se informa que o utilizador, ao avançar com o presente registo no Balcão Artes (e-Registo), confere o seu
      consentimento à DGARTES para o tratamento dos seus dados no âmbito da gestão,
      monitorização e execução dos Programas de Apoio às Artes. As falsas declarações serão punidas nos termos da Lei em
      vigor.
    </p>
    <br />
    <p style="opacity: 0.7; font-size: 80%">Campo Grande, nº 83 -1º, 1700-088 Lisboa | Tel. (+351) 211 507 010 |
      e.geral@dgartes.pt | www.dgartes.gov.pt</p>
  </div>

  <button mat-flat-button color="primary" (click)="onConfirmAction('accept')" class="mt-4">Aceitar</button>
</div>



<div *ngIf="data.type === 'perfil-list'" class="content large-modal">
  <div (click)="close()" class="close-icon"><img src="./assets/imgs/icons/cross.svg" alt="X" /></div>
  <h1 class="perfil-list">Lista de perfis</h1>

  <ul class="numered-list">
    <li class="numered-list-item dotted-modfy">
      <h2 data-bs-toggle="collapse" data-bs-target="#dialog-name" aria-expanded="false" aria-controls="dialog-name">
        <div></div>
        PERFIL EXEMPLO
      </h2>

      <div class="is-show-control">
        <div class="collapse multi-collapse height-limit" id="dialog-name">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
          duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
          tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
          duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
          Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
          tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
          vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
          Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
          elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
          vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
          no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
          elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
          erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
          no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
          amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
          erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
          et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
          duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
          tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
          duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
          Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
          tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
          vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
          Lorem ipsum dolor sit
        </div>
      </div>
    </li>
    <li class="numered-list-item dotted-modfy">
      <h2 data-bs-toggle="collapse" data-bs-target="#dialog-name1" aria-expanded="false" aria-controls="dialog-name1">
        <div></div>
        PERFIL EXEMPLO
      </h2>

      <div class="is-show-control">
        <div class="collapse multi-collapse height-limit" id="dialog-name1">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
          duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
          tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
          duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
          Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
          tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
          vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
          Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
          elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
          vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
          no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
          elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
          erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
          no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
          amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
          erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
          et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
          duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
          tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
          duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
          Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
          tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
          vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
          Lorem ipsum dolor sit
        </div>
      </div>
    </li>
  </ul>

  <button mat-stroked-button color="primary" (click)="close()" class="mt-3">Fechar</button>
</div>

<div *ngIf="data.type === 'alterar-estado'" class="content">
  <h1>Alterar estado</h1>

  <label class="d-block text-left">Estado</label>

  <mat-form-field appearance="outline">
    <mat-select placeholder="Inserir" [(ngModel)]="estado">
      <mat-option [value]="3" *ngIf="data.meta.isTecnico">PROPOSTA DE INDEFERIMENTO</mat-option>
      <mat-option [value]="4" *ngIf="data.meta.isTecnico">PROPOSTA DE DEFERIMENTO</mat-option>

      <mat-option [value]="5" *ngIf="data.meta.isDiretor">ACEITE</mat-option>
      <mat-option [value]="6" *ngIf="data.meta.isDiretor">INDEFERIDO</mat-option>
    </mat-select>
  </mat-form-field>

  <label class="d-block text-left">Observações</label>
  <mat-form-field appearance="outline">
    <textarea style="resize: none" matInput maxlength="1000" placeholder="Inserir" [(ngModel)]="observacoes"></textarea>
  </mat-form-field>

  <div class="d-flex m-4 gap-4">
    <button mat-stroked-button color="primary" (click)="close()">cancelar</button>
    <button [disabled]="!observacoes.length || !estado" mat-raised-button color="primary"
      (click)="onConfirmAction('estado')">gravar</button>
  </div>
</div>

<div *ngIf="data.type === 'filter-bolsa'" class="content">
  <h1>Exportar</h1>

  <mat-label class="d-block text-left">Seleciona um range de data de submissão <span
      class="blue-label">(obrigatório)</span></mat-label>
  <mat-form-field appearance="outline">
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Data inicial" />
      <input matEndDate formControlName="end" placeholder="Data final" />
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>

  <label class="d-block text-left">Estado <span class="blue-label">(obrigatório)</span></label>
  <mat-form-field appearance="outline">
    <mat-select placeholder="Inserir" [(ngModel)]="estado">
      <mat-option [value]="1">ACEITE</mat-option>
      <mat-option [value]="2">ATIVO</mat-option>
      <mat-option [value]="3">AUDIÊNCIA DE INTERESSADOS</mat-option>
      <mat-option [value]="4">APRECIAÇÃO</mat-option>
      <mat-option [value]="5">FUNÇÕES</mat-option>
      <mat-option [value]="6">INATIVO SUSPENSO</mat-option>
      <mat-option [value]="7">INDEFERIDO</mat-option>
      <mat-option [value]="8">INSCRIÇÃO CESSADA</mat-option>
      <mat-option [value]="9">SUBMETIDO</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="d-flex m-4 gap-4">
    <button mat-stroked-button color="primary" (click)="close()">cancelar</button>
    <button mat-raised-button color="primary" [disabled]="!(estado !== 0 && range.value.end && range.value.start)"
      (click)="onConfirmAction('export')">exportar</button>
  </div>
</div>

<div *ngIf="data.type === 'publicar-concurso'" class="content" [formGroup]="form">
  <h1>AGENDAR PUBLICAÇÃO</h1>
  <div class="container-fluid text-left">
    <div class="row">
      <div class="col">
        <mat-checkbox formControlName="publicarAgora" color="primary"
          (change)="form.get('agendar')?.setValue(!form.get('publicarAgora')?.value)">
          <p class="fw-bold m-0">Publicar Agora</p>
        </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-checkbox formControlName="agendar" color="primary"
          (change)="form.get('publicarAgora')?.setValue(!form.get('agendar')?.value)">
          <p class="fw-bold m-0">Agendar</p>
        </mat-checkbox>
      </div>
    </div>

    <div class="row" *ngIf="form.get('agendar')?.value">
      <div class="col-lg-12">
        <mat-form-field appearance="outline">
          <input matInput (focus)="datePickerPublicacao.open()" [ngxMatDatetimePicker]="datePickerPublicacao"
            placeholder="Selecionar" formControlName="dataPublicacao">
          <mat-datepicker-toggle matSuffix (click)="datePickerPublicacao.open()">
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #datePickerPublicacao [showSeconds]="true" [enableMeridian]="false">
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="d-flex m-4 gap-4">
    <button mat-stroked-button color="primary" (click)="close()">cancelar</button>
    <button mat-raised-button color="primary" [disabled]="!form.valid"
      (click)="onConfirmAction('confirm')">publicar</button>
  </div>
</div>

<div *ngIf="data.type === 'criar-lista-verificacao'" class="content" [formGroup]="form">
  <div class="title confirm_action">
    <img src="./assets/imgs/icons/question_baloon.svg" alt="confirm_action" />
  </div>

  <div class="text-content mt-3">
    <p>
      Tem a certeza que pretende avançar com a <br />
      ficha de verificação??
    </p>
  </div>

  <section [formGroup]="form" class="w-100">
    <p><mat-checkbox formControlName="exportpdf" class="w-100 text-left">exportar PDF</mat-checkbox></p>
  </section>

  <div class="d-flex gap-4" style="height: 48px !important">
    <button mat-stroked-button color="primary" (click)="close()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="onConfirmAction('exec')">Continuar</button>
  </div>
</div>