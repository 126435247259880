import { Component, Input } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
})
export class CollapseComponent {
  @Input() id!: string;
  @Input() title!: string;
  @Input() title2!: string;
  @Input() isCollapseOpen: boolean = false;
  @Input() noPadding: boolean = false;
  @Input() collapseParentDivId: string = '';

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
}
