<li class="numered-list-item" [class.dotted-modfy]="dotted" [class.last-item]="isLast">
  <h2
    data-bs-toggle="collapse"
    [attr.data-bs-target]="'#numered-item-' + numberedListParentId.replace('#', '') + number"
    [attr.aria-expanded]="startOpen ? 'true' : 'false'"
    [attr.aria-controls]="'#numered-item-' + numberedListParentId.replace('#', '') + number">
    <div>{{ dotted ? '' : number }}</div>
    {{ title }} <span class="smaller-title">{{ smallerTitle }}</span>
    <span *ngIf="isRequired" class="blue-label d-inline">(obrigatório)</span>
    <h4 class="subtitle" *ngIf="subtitle">{{ subtitle }}</h4>
  </h2>
  <div class="is-show-control">
    <div class="collapse multi-collapse" [class.show]="startOpen" [id]="'numered-item-' + numberedListParentId.replace('#', '') + number" [attr.data-bs-parent]="numberedListParentId">
      <ng-content></ng-content>
    </div>
  </div>
</li>
