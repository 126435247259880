import {
  AfterContentChecked,
  Component,
  ContentChildren,
  QueryList,
} from '@angular/core';
import { DottedListItemComponent } from './dotted-list-item/dotted-list-item.component';

@Component({
  selector: 'app-dotted-list',
  templateUrl: './dotted-list.component.html',
  styleUrls: ['./dotted-list.component.scss'],
})
export class DottedListComponent implements AfterContentChecked  {
  @ContentChildren(DottedListItemComponent)
  items: QueryList<DottedListItemComponent> | null = null;

  ngAfterContentChecked () {
    if (this.items) {
      this.items?.first.removeTopLine();
      this.items?.last.removeBottomLine();
    }
  }
}
