<form>
  <div class="mb-4" style="display: grid">
    <div class="col mb-4">
      <b>{{ title }}</b>
    </div>
    <div class="row gx-5" style="display: flex">
      <ng-container *ngIf="type === 'date'">
        <div *ngFor="let formGroup of fields; let i = index" [formGroup]="formGroup">
          <strong #errorAnchor style="color: var(--error); scroll-margin-top: 150px" *ngIf="dates[i] && dates[i].markFieldsAsInvalid">
            {{ dates[i].markFieldsAsInvalidMessage || 'Preenchimento inválido' }}
          </strong>

          <div class="fields-container" [style.border-color]="dates[i] && dates[i].markFieldsAsInvalid ? ' var(--error) ' : ''">
            <div class="col-lg-8" style="display: flex; gap: 20px">
              <div>
                <label class="d-block"> Data de início </label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    (focus)="datePickerInicio.open()"
                    [ngxMatDatetimePicker]="datePickerInicio"
                    placeholder="Selecionar"
                    formControlName="dataInicio"
                    [max]="form.get('dataFim')?.value" />
                  <mat-datepicker-toggle matSuffix (click)="datePickerInicio.open()"> </mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #datePickerInicio [enableMeridian]="false"> </ngx-mat-datetime-picker>
                  <mat-error>Preenchimento inválido</mat-error>
                </mat-form-field>
              </div>

              <div>
                <label class="d-block"> Data de fim </label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    (focus)="datePickerFim.open()"
                    [ngxMatDatetimePicker]="datePickerFim"
                    placeholder="Selecionar"
                    formControlName="dataFim"
                    [max]="form.get('dataFim')?.value"
                    [min]="form.get('dataInicio')?.value" />
                  <mat-datepicker-toggle matSuffix (click)="datePickerFim.open()"> </mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #datePickerFim [enableMeridian]="false"> </ngx-mat-datetime-picker>
                  <mat-error>Preenchimento inválido</mat-error>
                </mat-form-field>
              </div>

              <div style="align-self: center" *ngIf="i == fieldsLength - 1" class="">
                <fa-icon *ngIf="plusButton" [icon]="faPlus" class="icon-edit" (click)="addFields()"> </fa-icon>
              </div>
              <div style="align-self: center" *ngIf="shouldItShowTheDeleteButton(i)" class="">
                <fa-icon *ngIf="plusButton" [icon]="faTrashAlt" class="icon-trash" (click)="removeFields(i)"> </fa-icon>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="type === 'datetime'">
        <div *ngFor="let formGroup of fields; let i = index" [formGroup]="formGroup">
          <strong #errorAnchor style="color: var(--error); scroll-margin-top: 150px" *ngIf="dates[i] && dates[i].markFieldsAsInvalid">
            {{ dates[i].markFieldsAsInvalidMessage || 'Preenchimento inválido' }}
          </strong>

          <div class="fields-container" [style.border-color]="dates[i] && dates[i].markFieldsAsInvalid ? ' var(--error) ' : ''">
            <div class="col-lg-5">
              <div class="row">
                <label class="d-block"> Data e hora de início </label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    (focus)="datePickerInicio.open()"
                    [ngxMatDatetimePicker]="datePickerInicio"
                    placeholder="Selecionar"
                    formControlName="dataInicio"
                    [max]="form.get('dataFim')?.value" />
                  <mat-datepicker-toggle matSuffix (click)="datePickerInicio.open()"> </mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #datePickerInicio [showSeconds]="true" [enableMeridian]="false"> </ngx-mat-datetime-picker>
                  <mat-error>Preenchimento inválido</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="display-grid-center"><hr class="hr-date" /></div>

            <div class="col-lg-5">
              <div class="row">
                <label class="d-block"> Data e hora de fim </label>
                <mat-form-field appearance="outline">
                  <input matInput (focus)="datePickerFim.open()" [ngxMatDatetimePicker]="datePickerFim" placeholder="Selecionar" formControlName="dataFim" [max]="form.get('dataFim')?.value" />
                  <mat-datepicker-toggle matSuffix (click)="datePickerFim.open()"> </mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #datePickerFim [showSeconds]="true" [enableMeridian]="false"> </ngx-mat-datetime-picker>
                  <mat-error>Preenchimento inválido</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div style="align-self: center" *ngIf="i == fieldsLength - 1" class="">
              <fa-icon *ngIf="plusButton" [icon]="faPlus" class="icon-edit" (click)="addFields()"> </fa-icon>
            </div>
            <div style="align-self: center" *ngIf="shouldItShowTheDeleteButton(i)" class="">
              <fa-icon *ngIf="plusButton" [icon]="faTrashAlt" class="icon-trash" (click)="removeFields(i)"> </fa-icon>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</form>
