<div class="contatos-list mt-3">
  <p class="animate-slide-down" style="opacity: 0; animation-delay: 0.1s">
    Campo Grande, n.º 83 - 1º 1700-088 Lisboa<br>
    <fa-icon [icon]="faEnvelope" class="me-2"/>geral@dgartes.pt<br>
    <fa-icon [icon]="faPhone" class="me-2"/>(+351) 211 507 010<br>
  </p>
  <p class="animate-slide-down" style="opacity: 0; animation-delay: 0.2s">
    Horário de atendimento:<br>
    9h30-12h30 / 14h30-17h00
  </p>

  <div class="d-flex align-items-center gap-4 mt-4 animate-slide-down" style="opacity: 0; animation-delay: 0.3s">
    <a href="https://www.facebook.com/DGArtes" target="_blank" class="social">
      <fa-icon [icon]="faFacebook" class="m-0"/>
    </a>

    <a href="https://www.instagram.com/dg.artes/" target="_blank" class="social">
      <fa-icon [icon]="faInstagram" class="m-0"/>
    </a>
    
    <a href="https://www.youtube.com/channel/UCdHTVH-gNDaooyoo7vCFJxg" target="_blank" class="social">
      <fa-icon [icon]="faYoutube" class="m-0"/>
    </a>
  </div>
</div>