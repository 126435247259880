import { Pipe, PipeTransform } from '@angular/core';

type E = {
  0: 'EFETIVO';
  1: 'SUPLENTE';
};

@Pipe({
  name: 'comissaoMembroEnum',
})
export class ComissaoMembroEnumPipe implements PipeTransform {
  enumDecoder = {
    0: 'EFETIVO',
    1: 'SUPLENTE',
  };

  transform(value: number): string;

  transform(): E;

  transform<V extends number>(value?: V) {
    const d = this.enumDecoder as Record<string, string>;

    if (typeof value === 'number' && d[value]) return d[value] as string;

    return d as E;
  }
}
