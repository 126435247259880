<div class="d-flex h-100 w-100">
  <div class="fake-border h-100">
    <div class="top-line" *ngIf="showTop"></div>
    <div class="dot"></div>
    <div class="bottom-line" *ngIf="showBottom"></div>
  </div>
  <div class="w-100 h-100">
    <mat-accordion  displayMode="flat" title="">
      <mat-expansion-panel #accordion  hideToggle
      >
        <mat-expansion-panel-header
          collapsedHeight="50px"
          expandedHeight="50px"
          class="expansion-panel-header"
          [ngStyle]="{'pointer-events': isEditing ? 'none' : 'auto'}"
        >
          <mat-panel-title
          (keydown.space)="$event.stopImmediatePropagation()"
          >

          <span class="item-title me-3" *ngIf="!isEditing">{{ title }}</span>
            <input
              #inputEdit
              maxlength="50"
              [ngStyle]="{ display: isEditing ? 'block' : 'none' }"
              type="text"
              [size]="title.length || 1"
              [(ngModel)]="title"
              class="edit-input"
            />
            <div class="actions" *ngIf="!isEditing">
              <fa-icon
                [icon]="faEdit"
                title="Editar"
                class="me-2 icon-edit"
                (click)="onEdit(inputEdit, $event)"
              ></fa-icon>
              <fa-icon
                [icon]="faTrash"
                class="icon-trash"
                title="Excluir"
                (click)="onDelete($event)"
              ></fa-icon>
            </div>
            <div class="actions-editing" *ngIf="isEditing">
              <fa-icon
                [icon]="faCheck"
                title="Confirmar"
                class="me-2 icon-check"
                (click)="editDone($event)"
              ></fa-icon>
              <fa-icon
                [icon]="faTimesCircle"
                title="Cancelar"
                class="icon-cancel"
                (click)="cancelEdit($event)"
              ></fa-icon>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-content></ng-content>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
