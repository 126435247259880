import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'irsPipe' })
export class IrsPipe implements PipeTransform {
  transform(value: number): string {
    const estadosIrs = ['100% dos rendimentos', '50% - nos termos do n.º 1 do artigo 101.º-D do CIRS', '25% - nos termos do n.º 3 do artigo 101.º-D do CIRS'];

    return estadosIrs[value - 1];
  }
}
