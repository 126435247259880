import { Directive, ElementRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';

const DATEPICKER_YEAR = {
    parse: {
      dateInput: 'YYYY',
    },
    display: {
      dateInput: 'YYYY',
    },
  };
  
@Directive({
selector: '[yearCalendar]',
providers: [
    {provide: MAT_DATE_FORMATS, useValue: DATEPICKER_YEAR},
]
})
export class YearCalendarDirective {
}