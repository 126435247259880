import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DottedListItemComponent } from './dotted-list-item/dotted-list-item.component';
import { DottedListComponent } from './dotted-list.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    DottedListItemComponent,
    DottedListComponent
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    FontAwesomeModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    DottedListItemComponent,
    DottedListComponent
  ],
})
export class DottedListModule { }
