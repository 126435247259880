import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Verificadores'
})
export class VerificadorPipe implements PipeTransform {
  transform(value: number): string {    
    // Por Verificar = 1
    // Em Curso = 2
    // Concluido = 3

    const verificador = [
      'Sim',
      'Não',
      'Revisão'
    ]

    return verificador[+value];
  }
}
