import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'membro'})
export class Membro implements PipeTransform {
  transform(value: number): string {
  

    const estadosMembro = [
      'EFETIVO',
      'SUPLENTE',   
    ]
    return estadosMembro[value ];
  }
}


