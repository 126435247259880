import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Money'
})
export class MoneyPipe implements PipeTransform {

  transform(value: number): string {
    // como vem number, ao fazer to string as casas decimais ficam separadas por ponto
    let valueFinal = value.toString();
    let valueEuros = valueFinal.split('.')[0]; // agarra so nos euros
    let valueCentimos = valueFinal.split('.')[1]; // agarra so nos centimos

    if (!valueCentimos) {
      valueCentimos = '00';
    }

    if (!valueEuros) {
      valueEuros = '0';
    }

    // Remove os zeros à esquerda quando o montante for maior ou igual a 1 euro
    if (valueEuros.length > 1 && valueEuros.startsWith('0')) {
      valueEuros = valueEuros.replace(/^0+/, '');
    }

    // Adiciona pontos a cada grupo de três dígitos à esquerda do valor
    if (valueEuros.length > 3) {
      const grupos = [];
      let i = valueEuros.length;
      while (i > 0) {
        grupos.unshift(valueEuros.slice(Math.max(i - 3, 0), i));
        i -= 3;
      }
      valueEuros = grupos.join('.');
    }

    valueFinal = valueEuros + ',' + valueCentimos;

    return `€ ${valueFinal}`;
  }

}
