import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'estadoBolsa'})
export class EstadoBolsa implements PipeTransform {
  transform(value: number): string {
    /*
      Submetido = 1,
      InscricaoCessada = 2,
      Aceite = 3,
      Indeferido = 4,
      PropostaIndeferimento = 5,
      PropostaAceitacao = 6
    */

    const estadosBolsa = [
      'Submetido',
      'Inscricao Cessada',
      'Aceite',
      'Indeferido',
      'Proposta de Indeferimento',
      'Proposta de Aceitação'
    ]

    return estadosBolsa[+value - 1];
  }
}
