import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseDefault } from 'src/app/shared/interfaces/response-default.interface';
import { Auth } from 'src/app/shared/interfaces/auth.model';
import { Activate } from 'src/app/shared/interfaces/activate.model';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl = `${environment.apiUrl}/auth`;
  userType = '';

  constructor(private http: HttpClient, private route: Router, private userService: UserService) {}

  login(login: string, password: string): Observable<ResponseDefault<Auth>> {
    const url = `${this.baseUrl}/external/login`;
    return this.http.post<ResponseDefault<Auth>>(url, { login, password }).pipe(timeout(10000));
  }

  loginAuxiliar(email: string, password: string): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/auxiliar/login`;
    return this.http.post<ResponseDefault<any>>(url, { email, password }).pipe(timeout(10000));
  }

  loginGov(token: string): Observable<ResponseDefault<Auth>> {
    const url = `${this.baseUrl}/external/login/govpt`;
    return this.http.post<ResponseDefault<Auth>>(url, { token }).pipe(timeout(10000));
  }

  loginIndividualColetivo(externalUserIndividual: string, externalUserColetivo: string, token: string): Observable<ResponseDefault<Auth>> {
    const url = `${this.baseUrl}/individual-coletivo/login`;
    let usersAuth = {
      externalUserIndividual: externalUserIndividual,
      externalUserColetivo: externalUserColetivo,
    };
    let header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
    };
    return this.http.post<any>(url, usersAuth, header).pipe(timeout(10000));
  }

  loginAd(token: string): Observable<any> {
    const url = `${this.baseUrl}/backoffice/login`;
    let header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
    };
    return this.http.post<any>(url, undefined, header);
  }

  activate(code: string): Observable<ResponseDefault<Activate>> {
    const url = `${this.baseUrl}/external/activate/${code}`;
    return this.http.patch<ResponseDefault<Activate>>(url, code);
  }

  logout() {
    this.route.navigate(['']);
    localStorage.clear();
  }

  isLoggedIn() {
    return localStorage.getItem('token') !== null || localStorage.getItem('userToken') !== null;
  }

  putCredentials(email: string, currentlyPassword: string, newPassword?: string) {
    const url = `${this.baseUrl}/external/update-credentials`;
    const newCredentials = {
      email: email,
      currentPassword: currentlyPassword,
      newPassword: newPassword ?? null,
    };
    return this.http.put(url, newCredentials);
  }

  putAuxiliarCredentials(id: string, password: string): Observable<ResponseDefault<any>> {
    const url = `${this.baseUrl}/auxiliar/new-password`;
    return this.http.put<ResponseDefault<any>>(url, { id, password });
  }

  onSuccessfulLogin(auth: Auth) {
    localStorage.setItem('token', auth?.token ?? '');
    localStorage.setItem('user', JSON.stringify(auth));
    localStorage.setItem('userToken', JSON.stringify(auth?.token)); // esta token nunca ira alterar sera sempre o original
    localStorage.setItem('userId', auth?.user.id ?? '');

    this.userService.setOriginalUserTokenAndId(auth?.token ?? '', auth?.user.id ?? '');

    if ([3, 2, 1].includes(auth?.user.tipoUtilizador)) {
      this.route.navigate(['home']);
    }
  }

  sendEmailEsqueciSenha(email: string) {
    const url = `${this.baseUrl}/external/send-email-esqueci-senha`;
    return this.http.post(url, email);
  }

  esqueciSenha(id: string, password: string) {
    const url = `${this.baseUrl}/external/esqueci-senha`;
    const newPost = {
      id,
      password,
    };
    return this.http.post(url, newPost);
  }

  getPermissionsForUser(userColetivoSelectedGuid: string, userIndividualOriginalGuid: string): Observable<ResponseDefault<any>> {
    return this.http.get<ResponseDefault<any>>(this.baseUrl + '/external/GetUserPermissions?userColetivoId=' + userColetivoSelectedGuid + '&userIndividualId=' + userIndividualOriginalGuid);
  }
}
