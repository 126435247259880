import { Dialog } from '@angular/cdk/dialog';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnChanges {
  @Input() icon: IconDefinition | null = null;
  @Input() title = '';
  @Input() customButtonTitle = '';
  @Input() description: string | null = null;
  @Input() isRequired = false;
  @Input() isApplicable = false;
  @Input() showMessageUpload = false;

  @Input() uploadFileSizeLimitKB: number = 5120; // limite pré-definido de upload de ficheiros (default 5MB = 5120KB | 1MB = 1024KB)
  @Input() acceptableFileExtensions: string[] = new Array(); // declarar tipos aceites ['pdf', 'odt', 'docx']
  // nao esquecer os formatos opensource ! DOCX tem o opensource ODT por exemplo!
  @Input() type: 'document' | 'image' = 'document';
  @Input() noLabel = false;

  @Output() arquivoSelecionado = new EventEmitter<File | null>();
  @Output() arquivosSelecionados = new EventEmitter<File[] | null>();

  @Input() currentFile: File | null = null;

  @Input() readOnlyMode = false; // disables the upload of new files

  /* Multiple files upload */
  @Input() allowMultipleFiles = false; // allows the upload of multiple files
  @Input() multipleFilesLimit = 3;
  @Input() currentFiles: File[] | null = null;

  currentDate: Date | null = null;

  constructor(private sanitizer: DomSanitizer, private elementRef: ElementRef, private dialog: Dialog) {}

  ngOnChanges(): void {
    if (this.type === 'image' && typeof this.currentFile === 'string') {
      this.imgUrl = this.currentFile;
    }

    if (!this.currentFile) {
      this.clear();
    }
  }

  selecionarArquivo(event: any) {
    const files: FileList = event.target.files;

    if (this.allowMultipleFiles) {
      if (files.length > this.multipleFilesLimit) {
        this.dialog.open(DialogComponent, {
          data: {
            type: 'custom-error',
            message: ['O número de ficheiros máximo permitido é de ' + this.multipleFilesLimit + ' ficheiros.'],
          },
        });
        return;
      } else {
        this.currentFiles = [];
        Array.from(files).forEach(file => {
          if (this.validateFile(file)) {
            this.currentFiles?.push(file);
          }
        });

        if (this.currentFiles.length > 0) {
          this.arquivosSelecionados.emit(this.currentFiles);
        }
      }
    } else {
      const file = files.item(0);
      if (file && this.validateFile(file)) {
        this.currentFile = file;
        this.getFileUrl(this.currentFile);
        this.arquivoSelecionado.emit(this.currentFile);
      }
    }
  }

  validateFile(file: File): boolean {
    // Check file size
    const fileSizeKB = file.size / 1024; // Convert Bytes to KB
    if (fileSizeKB > this.uploadFileSizeLimitKB) {
      this.dialog.open(DialogComponent, {
        data: {
          type: 'custom-error',
          message: ['O ficheiro selecionado excede o tamanho máximo permitido de ' + this.uploadFileSizeLimitKB / 1024 + ' MB.'],
        },
      });
      return false;
    }

    // Check file extension, if necessary
    if (this.acceptableFileExtensions.length > 0) {
      const extensionsList = this.acceptableFileExtensions.map(extension => extension.replace('.', '').toLowerCase());
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (fileExtension && !this.acceptableFileExtensions.includes('*') && !extensionsList.includes(fileExtension)) {
        this.dialog.open(DialogComponent, {
          data: {
            type: 'custom-error',
            message: ['Selecionou um ficheiro com um formato inválido.\nFormato(s) aceite(s): ' + extensionsList.map(ext => ext.toUpperCase()).join(', ')],
          },
        });
        return false;
      }
    }

    // File is valid
    return true;
  }

  clear(fileToClear?: File) {
    const fileInput = this.elementRef.nativeElement.querySelector('.upload-file_document, .upload-file_image');
    if (fileInput) {
      fileInput.value = '';
    }

    if (this.allowMultipleFiles && fileToClear) {
      this.currentFiles = (this.currentFiles ?? []).filter(file => file !== fileToClear);
      if (this.currentFiles.length === 0) {
        this.currentDate = null;
        this.currentFiles = null;
      }
      this.arquivosSelecionados.emit(this.currentFiles);
    } else {
      this.currentDate = null;
      this.currentFile = null;
      this.arquivoSelecionado.emit(this.currentFile);
    }
  }

  imgUrl: SafeUrl | null = null;

  getFileUrl(file: File | null) {
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      this.imgUrl = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
    }
  }

  getImgUrl(file: File): SafeUrl {
    if (!file) return '';

    const imageUrl = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
}
