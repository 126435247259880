import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss']
})


export class FormTemplateComponent{

    @Input() context:any;
    @Output() setFormDirective = new EventEmitter<NgForm>()
    @ViewChild('formDirective') private form!: NgForm;

    ngAfterViewInit() {
        this.setFormDirective.emit(this.form)
    }

}