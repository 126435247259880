<div class="animate-slide-right w-100">
  <div class="pills-list-wrapper">
    <div class="pill">
      <div class="pill-title">Responsável(eis):</div>
      <div class="pill-content">
        <ng-container *ngFor="let responsavel of responsaveis, index as iResp">
          {{ responsavel.nomeCompleto }} <span *ngIf="iResp < (responsaveis.length - 1)">, </span>
        </ng-container>
        <span *ngIf="!responsaveis.length">(Nenhum Definido)</span>
      </div>
    </div>
    <div class="pill">
      <div class="pill-title">Data de início estimada:</div>
      <div class="pill-content">{{ dataInicio ? (dataInicio | date : 'dd/MM/YYYY') : '(Não Definida)' }}</div>
    </div>
    <div class="pill">
      <div class="pill-title">Data de fim estimada:</div>
      <div class="pill-content">{{ dataFim ? (dataFim | date : 'dd/MM/YYYY') : '(Não Definida)' }}</div>
    </div>
  </div>
</div>
<hr />
