import { Component, Input } from '@angular/core';
import { faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import { UserService } from './../../services/user.service';

@Component({
  selector: 'app-profile-selector',
  templateUrl: './profile-selector.component.html',
  styleUrls: ['./profile-selector.component.scss']
})

export class ProfileSelector {
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  currentIcon = faAngleUp;
  claims: string[] = [];
  public disabled: string[] = [];
  isOpen = false;

  constructor(
  	public user: UserService
  ){
  	this.claims = user.getRawUserClaims();
  	this.updateClaims();
  }

  toggle(){
  	this.isOpen = !this.isOpen;
  	this.currentIcon = (this.isOpen)? faAngleDown : faAngleUp;
  }

  updateClaims(){
  	let enabled = this.user.getUserClaims();
  	this.disabled = this.claims.filter((x: string) => enabled.indexOf(x) === -1);
  }

  changed(event: any){
  	let checked = event.checked;
  	let name = event.source.name;

  	if( !checked ){
  		this.disabled.push(name);
  		return this.save();
  	}

  	this.disabled = this.disabled.filter(x => x !== name )
  	this.save();
  }

  save(){
  	let disabledClaims = localStorage.setItem('disabledClaims', JSON.stringify(this.disabled));
  	this.updateClaims();
  }

  refresh(){
  	window.location.reload()
  }
}
