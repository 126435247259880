import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relacaoContratualRH'
})
export class RelacaoContratualRHPipe implements PipeTransform {

  transform(value: number): string {
   
    const relacaoContratualRH = [
      '-',
      'Contrato de Trabalho por Tempo Indeterminado',
      'Contrato de Trabalho a Termo Resolutivo Certo',
      'Contrato de Trabalho a Termo Resolutivo Incerto',
      'Contrato de Trabalho de Muito Curta Duração',
      'Contrato de Trabalho com Atividade Descontínua',
      'Contrato de Trabalho com Pluralidade de Empregadores',
      'Contrato-Promessa de Trabalho',
      'Contrato de Prestação de Serviço',
      'Contrato de Estágio',
      'Voluntariado',
      'Outros'
    ]

    return relacaoContratualRH[value] || '';
  }

}
