<div *ngIf="hasHeader; else noHeader">
  <app-header [userType]="userType" [userName]="userName"/>
  <div [ngClass]="hasPadding ? 'container-fluid content' : ''">
    <router-outlet />
  </div>
</div>

<ng-template #noHeader>
  <router-outlet />
</ng-template>
<!-- <app-profile-selector *ngIf="!isProduction && (userType=='InternalUser')">
  <survey-creator-component></survey-creator-component>
</app-profile-selector> -->
<app-scroll-btn></app-scroll-btn>
