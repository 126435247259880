import { Component } from '@angular/core';

@Component({
  selector: 'app-changes-history',
  templateUrl: './changes-history.component.html',
  styleUrls: ['./changes-history.component.scss'],
})
export class ChangesHistoryComponent {
  list = [
    {
      description: 'Nenhuma',
      type: 'flagged',
    },
    {
      type: 'description',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque veritatis doloremque nulla. Dolorem, quod.',
    },
    {
      type: 'description',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque veritatis doloremque nulla. Dolorem, quod.',
    },
    {
      type: 'description',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi laboriosam suscipit quas recusandae. Amet nihil similique blanditiis aliquid vel nemo aperiam magni voluptatem mollitia cum enim doloribus sint unde, aut fuga non et dignissimos ratione veniam quisquam voluptates error. Enim.',
    },
  ];
}
