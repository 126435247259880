import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'DominioArtistico'
})
export class DominioArtisticoPipe implements PipeTransform {

  transform(value: number): string {
    const dominioArtistica = [
      'Criação',
      'Programação',
      'Circulação nacional',
      'Internacionalização',
      'Ações estratégicas de mediação',
      'Edição',
      'Investigação',
      'Formação'
    ]

    return dominioArtistica[+value - 1];
  }

}
