import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appMoneyMask]'
})
export class MoneyMaskDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/[^\d]/g, ''); // Remove todos os caracteres não numéricos
    const centavos = value.slice(-2); // Obtém os últimos dois dígitos (centavos)
    let montanteInteiro = value.slice(0, -2).padStart(1, '0'); // Obtém o montante inteiro e preenche com zeros à esquerda se menor que 1 euro

    // Remove os zeros à esquerda quando o montante for maior ou igual a 1 euro
    if (montanteInteiro.length > 1 && montanteInteiro.startsWith('0')) {
      montanteInteiro = montanteInteiro.replace(/^0+/, '');
    }

    // Adiciona pontos a cada grupo de três dígitos à esquerda do valor
    if (montanteInteiro.length > 3) {
      const grupos = [];
      let i = montanteInteiro.length;
      while (i > 0) {
        grupos.unshift(montanteInteiro.slice(Math.max(i - 3, 0), i));
        i -= 3;
      }
      montanteInteiro = grupos.join('.');
    }

    value = montanteInteiro + ',' + centavos;

    input.value = `€ ${value}`;
  }
}
