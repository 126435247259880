import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'verificacaoCandidaturaConcursoPlanificacoesResponsaveis'
})
export class VerificacaoCandidaturaConcursoPlanificacoesResponsaveisPipe implements PipeTransform {

  transform(value: any[]): string {
    
    let [planificacaoVerificacao] = value.filter(i => i.tipoPlanificacao === 2)

    let nomesPlanificacaoVerificacao = planificacaoVerificacao.internalUser.map((i:any) => i.nomeCompleto)
    
    let nomeApelidoPlanificacaoVerificacao = nomesPlanificacaoVerificacao.map((i: any) => {
      const nameParts = i.split(' ')
      const firstName = nameParts[0];
      const lastName = nameParts[nameParts.length - 1];
    
      return firstName + ' ' + lastName;
    })
    
    const returnedName = nomeApelidoPlanificacaoVerificacao.length > 1 ? nomeApelidoPlanificacaoVerificacao.join(', ') : nomeApelidoPlanificacaoVerificacao;

    return returnedName || '';
  }

}
