import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-fullscreen',
  templateUrl: './loading-fullscreen.component.html',
  styleUrls: ['./loading-fullscreen.component.scss']
})
export class LoadingFullscreenComponent implements OnInit {
  ngOnInit(): void {
    // esta funcao alinha o loading screen de forma sticky ao centro do ecra
    const fullPageHeight = document.documentElement.scrollHeight;
    let divElement = document.getElementById('outer-loading-fullscreen-div');
    if (divElement && fullPageHeight) {
      divElement.style.height = fullPageHeight + 'px';
    }
  }
}
