import { AfterViewInit, Component, Inject } from '@angular/core';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { faClock } from '@fortawesome/free-regular-svg-icons';


export interface DialogData {
  type:
    | 'confirm_action'
    | 'success'
    | 'custom-success'
    | 'error'
    | 'custom-error'
    | 'custom-confirm'
    | 'rgpd'
    | 'loading'
    | 'perfil-list'
    | 'alterar-estado'
    | 'filter-bolsa'
    | 'criar-lista-verificacao'
    | 'publicar-concurso'
    | 'atribuir-perfil'
    |'valid';
  message?: string[];
  confirmText?: string;
  onConfirm?: CallableFunction;
  form?: FormGroup;
  meta?: any;
  autoCloseTimeout?: null | number;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements AfterViewInit {
  estado = 0;
  observacoes = '';
  clockIcon = faClock;
  autoCloseTimeout: number | undefined;

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  form!: FormGroup;

  constructor(public dialogRef: DialogRef<string>, @Inject(DIALOG_DATA) public data: DialogData) {
    if (data.type == 'loading') {
      dialogRef.disableClose = true;
    }
    if (data.form) {
      this.form = data.form;
    }
    if (data.autoCloseTimeout) {
      this.autoCloseTimeout = data.autoCloseTimeout;
    }
  }

  ngAfterViewInit() {
    const scrollDiv = document.querySelector('.height-limit');
    setTimeout(() => scrollDiv?.scrollTo({ top: 0 }), 5);

    if (this.autoCloseTimeout && typeof this.autoCloseTimeout === 'number') {
      setTimeout(() => {
        this.close();
      }, this.autoCloseTimeout);
    }
  }

  close(data?: any) {
    const dialogWrapperDiv = document.querySelector('.cdk-overlay-container');
    const dialogContentDiv = document.querySelector('.cdk-dialog-container');

    dialogWrapperDiv?.classList.add('animate-dialog-closing-fadeOut');
    dialogContentDiv?.classList.add('animate-dialog-closing-zoomOut');
    setTimeout(() => {
      dialogWrapperDiv?.classList.remove('animate-dialog-closing-fadeOut');
      dialogContentDiv?.classList.remove('animate-dialog-closing-zoomOut');
      this.dialogRef.close(data ?? undefined);
    }, 300);
  }

  onConfirmAction(action: string) {
    if (action == 'estado') {
      let data = {
        estado: this.estado,
        observacoes: this.observacoes,
      };
      this.close(JSON.stringify(data));
      return;
    }
    if (action == 'export') {
      let data = {
        estado: this.estado,
        range: this.range.value,
      };
      this.close(JSON.stringify(data));
      return;
    }
    this.close(action);
    this.data.onConfirm && this.data.onConfirm();
  }

  debug(item: any) {
    console.log(item);
  }

  accept(): void {
    this.dialogRef.close('accept');
  }
}
