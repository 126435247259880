<div class="mt-3">
    <div data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + id" aria-expanded="false"
        [attr.aria-controls]="'#collapse' + id" class="card-title" (click)="isCollapseOpen = !isCollapseOpen">
        <div class="d-flex w-100 align-items-center justify-content-between gap-2 p-2 cursor-pointer" [class.collapse-content]="!isCollapseOpen">
            <fa-icon [icon]="isCollapseOpen ? faMinus : faPlus"></fa-icon>
            <span class="title w-100 flex-1">{{ pergunta }}</span>
            <ng-content></ng-content>
        </div>
    </div>
    <div class="collapse p-4" [attr.id]="'collapse' + id" [innerHTML]="resposta">
    </div>
</div>