import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef, ViewChild, DoCheck } from '@angular/core';
import { faClock, faCalendarAlt, faPenToSquare, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';

import { DateFieldsToSend, DateFieldsToManager, FormDateType } from 'src/app/shared/interfaces/date-fields-manager.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-date-fields-manager',
  templateUrl: './date-fields-manager.component.html',
  styleUrls: ['./date-fields-manager.component.scss'],
})
export class DateFieldsManagerComponent implements OnInit, OnChanges, DoCheck {
  @Input() title = '';
  @Input() type: FormDateType = 'date';
  @Input() plusButton: boolean = true;
  @Input() dates: DateFieldsToManager[] = [];
  @Input() onDatesChanges?: (newValues: DateFieldsToManager[]) => void;

  @ViewChild('errorAnchor') errorAnchor!: ElementRef<HTMLDivElement>;

  errorAnchorScrollled: HTMLDivElement | null = null;

  clockIcon = faClock;
  faCalendarAlt = faCalendarAlt;
  faPenToSquare = faPenToSquare;
  faTrashAlt = faTrashAlt;
  faPlus = faPlus;
  fields: FormGroup[] = [];
  fieldsLength = 0;

  form!: FormGroup<{
    dateRows: FormArray<FormControl<unknown>>;
  }>;

  formValueChangesUnsubscriber!: Subscription;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      dateRows: this.fb.array([]),
    });
  }

  ngOnInit(): void {}

  get dateRows(): FormArray {
    return this.form.get('dateRows') as FormArray;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['dates']) return;

    if (this.formValueChangesUnsubscriber) {
      this.formValueChangesUnsubscriber.unsubscribe();
    }

    this.fields.length = 0;
    this.dateRows.clear();
    this.fieldsLength = this.fields.length;

    if (this.dates && this.dates.length > 0) {
      this.dates.forEach(i => {
        this.addFields(i.dataInicio, i.dataFim);
      });
    } else {
      this.addFields();
    }
    this.formValueChangesSubscribe();
  }

  ngDoCheck() {
    this.scrollIntoViewError();
  }

  scrollIntoViewError() {
    if (!this.errorAnchor?.nativeElement || this.errorAnchorScrollled === this.errorAnchor.nativeElement) return;

    this.errorAnchorScrollled = this.errorAnchor.nativeElement;

    window.requestAnimationFrame(() => {
      this.errorAnchor.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  formValueChangesSubscribe() {
    this.formValueChangesUnsubscriber = this.form.valueChanges.subscribe(newValues => {
      if (!this.onDatesChanges) return;

      const values = newValues.dateRows;

      if (!values) return;

      const dateFields: DateFieldsToSend[] = values.map(i => {
        const item = i as any;
        return {
          dataInicio: item.dataInicio ? new Date(item.dataInicio).toISOString() : '',
          dataFim: item.dataFim ? new Date(item.dataFim).toISOString() : '',
        };
      });
      this.onDatesChanges(dateFields);
    });
  }

  addFields(dataInicio = '', dataFim = ''): void {
    const newRow = this.fb.group({
      dataInicio: [dataInicio, Validators.required],
      dataFim: [dataFim, Validators.required],
      picker1: [null],
      picker2: [null],
    });

    this.fields.push(newRow);
    this.dateRows.push(newRow);
    this.fieldsLength = this.fields.length;
  }

  shouldItShowTheDeleteButton(i: number) {
    return this.fieldsLength > 1;
  }

  removeFields(index: number): void {
    if (this.fields.length > 1) {
      this.fields.splice(index, 1);
      this.dateRows.removeAt(index);
    }
    this.fieldsLength = this.fields.length;
  }
}
