import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDateInputFormat]'
})
export class DateInputFormatDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value.replace(/[^0-9/]/g, ''); // Remove caracteres que não sejam números ou '/'
    input.value = this.formatDate(value);
  }

  private formatDate(value: string): string {
    const regex = /(\d{0,2})(\d{0,2})(\d{0,4})/;
    const parts = value.match(regex);
    if (!parts) {
      return '';
    }

    const day = parts[1];
    const month = parts[2];
    const year = parts[3];

    let formattedValue = '';

    if (day) {
      formattedValue += day;
      if (day.length === 2 && month) {
        formattedValue += '/';
      }
    }

    if (month) {
      formattedValue += month;
      if (month.length === 2 && year) {
        formattedValue += '/';
      }
    }

    if (year) {
      formattedValue += year;
    }

    return formattedValue;
  }
}
