import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estadoPercursoProfissional'
})
export class EstadoPercursoProfissionalPipe implements PipeTransform {

  transform(value: any): any {
    if(value === 1 || value === '1'){
      return 'Ativo';
    } else if(value === 2 || value === '2'){
      return 'Em Funções';
    } else if(value === 3 || value === '3'){
      return 'Inativo/Suspenso';
    } else return '-';
  }

}
