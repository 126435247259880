import { Pipe, PipeTransform } from '@angular/core';

type E = {
  0: 'COORDENADOR';
  1: 'ESPECIALISTA';
  2: 'TÉCNICO SUPERIOR DGARTES';
};

@Pipe({
  name: 'comissaoComposicaoEnum',
})
export class ComissaoComposicaoEnumPipe implements PipeTransform {
  enumDecoder = {
    0: 'COORDENADOR',
    1: 'ESPECIALISTA',
    2: 'TÉCNICO SUPERIOR DGARTES',
  };

  transform(value: number): string;

  transform(): E;

  transform<V extends number>(value?: V) {
    const d = this.enumDecoder as Record<string, string>;

    if (typeof value === 'number' && d[value]) return d[value] as string;

    return d as E;
  }
}
