<div *ngIf="type === 'document'">
  <div class="labels" *ngIf="!noLabel && !customButtonTitle">
    <label class="d-block" *ngIf="!isApplicable">
      <span *ngIf="!allowMultipleFiles">Anexo</span>
      <span *ngIf="allowMultipleFiles"> Anexo(s) (pode selecionar múltiplos ficheiros de uma só vez)</span>
    </label>

    <label class="d-block" *ngIf="isApplicable"> Documento contratual <span class="blue-label"> (se aplicável) </span> </label>
    <label class="d-block" *ngIf="isRequired"><span class="blue-label"> (obrigatório)</span></label>
  </div>
  <div class="labels" *ngIf="!noLabel && customButtonTitle">
    <label class="d-block">{{ customButtonTitle }} </label>
    <label class="d-block" *ngIf="isRequired"><span class="blue-label"> (obrigatório)</span></label>
  </div>
  <div class="upload-file">
    <label class="large">
      <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
      <p>{{ title }}</p>
      <span>
        {{ description ?? acceptableFileExtensions.join(', ') + ' (max. ' + uploadFileSizeLimitKB / 1024 + 'MB)' }}
        <!-- 1MB = 1024KB !!!! -->
      </span>
      <input [disabled]="readOnlyMode" matRipple type="file" class="upload-file_document" name="file-type" [multiple]="allowMultipleFiles" (change)="selecionarArquivo($event)" />
    </label>
  </div>

  <div *ngIf="!readOnlyMode">
    <ng-container *ngIf="allowMultipleFiles && currentFiles?.length && showMessageUpload">
      <div class="preview-span title">Pendente para upload:</div>
      <div *ngFor="let file of currentFiles" class="file-pending">
        <div class="preview-span">
          <div class="file-pending">
            <span class="blue-label">
              <mat-icon style="font-size: 20px; line-height: 1.3" class="blue-label"> upload_file </mat-icon>
              {{ file.name }}
            </span>
            <span class="date">
              {{ currentDate | date : 'dd/MM/YYYY' : 'UTC' }}
            </span>
            <span class="clear-x" (click)="clear(file)">
              <mat-icon style="font-size: 20px; line-height: 1.3" matTooltip="Cancelar o upload">cancel</mat-icon>
            </span>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!allowMultipleFiles && currentFile && !showMessageUpload">
      <div class="preview-span title">Pendente para upload:</div>
      <div class="preview-span">
        <div class="file-pending">
          <span class="blue-label">
            <mat-icon style="font-size: 20px; line-height: 1.3" class="blue-label"> upload_file </mat-icon>
            {{ currentFile.name }}
          </span>
          <span class="date">
            {{ currentDate | date : 'dd/MM/YYYY' : 'UTC' }}
          </span>
          <span class="clear-x" (click)="clear()">
            <mat-icon style="font-size: 20px; line-height: 1.3" matTooltip="Cancelar o upload">cancel</mat-icon>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<label class="dados_image-upload" [ngClass]="{ 'remove-bg': currentFile, disabled: readOnlyMode }" *ngIf="type === 'image'">
  <input [disabled]="readOnlyMode" type="file" class="upload-file_image" [multiple]="allowMultipleFiles" (change)="selecionarArquivo($event)" />
  <ng-container *ngIf="allowMultipleFiles">
    <img *ngFor="let file of currentFiles" [src]="getImgUrl(file)" [alt]="file.name" />
    <!-- Loop through images -->
  </ng-container>
  <img *ngIf="!allowMultipleFiles && currentFile" [src]="imgUrl ? imgUrl : currentFile" [alt]="imgUrl ? imgUrl : currentFile" />
</label>
