import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { DialogComponent } from './components/dialog/dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DottedListModule } from './components/dotted-list/dotted-list.module';
import { ChangesHistoryModule } from './components/changes-history/changes-history.module';
import { MenuModule } from './components/menu/menu.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingComponent } from './components/loading/loading.component';
import { LoadingFullscreenComponent } from './components/loading-fullscreen/loading-fullscreen.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NumbersOnlyDirective } from './helpers/numbers-only.mask';
import { FormTemplateComponent } from './components/form/form-template.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NifDirective } from './directives/nif.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { EstadoBolsa } from './pipes/EstadoBolsa.pipe';
import { AnosExperienciaProfissionalBolsa } from './pipes/AnosExperienciaProfissionalBolsa.pipe';
import { NumeredListModule } from './components/numered-list/numered-list.module';
import { CollapseFaqComponent } from './components/collapse-faq/collapse-faq.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { FormasAtribuicao } from './pipes/FormasAtibuicao.pipe';
import { YearCalendarDirective } from './directives/year-calendar';
import { TimeMaskDirective } from './helpers/time.mask';
import { FasesConcursoHeader } from './components/fases-concurso-header/fases-concurso-header.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { MoneyMaskDirective } from './directives/money.directive';
import { EstadoCandidaturaPipe } from './pipes/estado-candidatura.pipe';
import { AreaArtisticaPipe } from './pipes/AreaArtistica.pipe';
import { DominioArtisticoPipe } from './pipes/DominioArtistico.pipe';
import { NutsllPipe } from './pipes/Nutsll.pipe';
import { MoneyPipe } from 'src/app/shared/pipes/Money.pipe';
import { EstadoVerificacaoCandidaturaPipe } from './pipes/EstadoVerificacaoCandidatura.pipe';
import { VerificacaoCandidaturaConcursoPlanificacoesResponsaveisPipe } from './pipes/VerificacaoCandidaturaConcursoPlanificacoesResponsaveis.pipe';
import { EstadosBOtoFOBolsaConsultoresEspecialistasPipe } from './pipes/EstadosBOtoFOBolsaConsultoresEspecialistas.pipe';
import { EstadoPercursoProfissionalPipe } from './pipes/EstadoPercursoProfissional.pipe';
import { TipoEquipaRHPipe } from './pipes/TipoEquipaRH.pipe';
import { RelacaoContratualRHPipe } from './pipes/RelacaoContratualRH.pipe';
import { MatIconModule } from '@angular/material/icon';
import { TipoProgramaDeApoioPipe } from './pipes/TipoProgramaDeApoio.pipe';
import { EstadoEquipamentoEspacoPipe } from './pipes/EstadoEquipamentoEspaco.pipe';
import { EstadoEquipaPipe } from './pipes/estado-equipa.pipe';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDateFormats, DateAdapter } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { getPaginatorIntlPT } from './customModules/matPaginatorPT';
import { OnlyLettersDirective } from './directives/only-letters.directive';
import { DateInputFormatDirective } from './directives/only-date.directive';
import { Separador } from './pipes/Separador.pipe';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { FilterSearchPipe } from './pipes/filter-search.pipe';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { DateFieldsManagerComponent } from './components/date-fields-manager/date-fields-manager.component';
import { ComissaoMembroEnumPipe } from './pipes/comissao-membro-enum.pipe';
import { ComissaoComposicaoEnumPipe } from './pipes/comissao-composicao-enum.pipe';
import { AppComponent } from '../app.component';

const PT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};
import { VerificacaoCandidaturaConcursoPlanificacaoDatafimPipe } from './pipes/VerificacaoCandidaturaConcursoPlanificacaoDatafim.pipe';
import { CandidaturaEstadoPipe } from './pipes/candidaturaEstado.pipe';
import { Composicao } from './pipes/composicao.pipe';
import { Membro } from './pipes/Membro.pipe';
import { SortAlphabeticallyPipe } from './pipes/sort-alphabetically.pipe';
import { VerificadorPipe } from './pipes/Verificador.pipe';
import { reciboPipe } from './pipes/reciboPipe.pipe';
import { IrsPipe } from './pipes/irsPipe.pipe';
import { EstadoComissao } from './pipes/estadoComissaoPipe.pipe';

@NgModule({
  declarations: [
    DialogComponent,
    LoadingComponent,
    LoadingFullscreenComponent,
    BreadcrumbComponent,
    NumbersOnlyDirective,
    TimeMaskDirective,
    FormTemplateComponent,
    NifDirective,
    PhoneInputComponent,
    NoDataComponent,
    CollapseComponent,
    EstadoBolsa,
    AnosExperienciaProfissionalBolsa,
    CollapseFaqComponent,
    FormasAtribuicao,
    YearCalendarDirective,
    FasesConcursoHeader,
    FileUploadComponent,
    MoneyMaskDirective,
    EstadoCandidaturaPipe,
    AreaArtisticaPipe,
    DominioArtisticoPipe,
    NutsllPipe,
    MoneyPipe,
    EstadoVerificacaoCandidaturaPipe,
    VerificacaoCandidaturaConcursoPlanificacoesResponsaveisPipe,
    VerificacaoCandidaturaConcursoPlanificacaoDatafimPipe,
    EstadosBOtoFOBolsaConsultoresEspecialistasPipe,
    EstadoPercursoProfissionalPipe,
    TipoEquipaRHPipe,
    RelacaoContratualRHPipe,
    TipoProgramaDeApoioPipe,
    EstadoEquipamentoEspacoPipe,
    EstadoEquipaPipe,
    OnlyLettersDirective,
    DateInputFormatDirective,
    Separador,
    FilterSearchPipe,
    DateFieldsManagerComponent,
    ComissaoMembroEnumPipe,
    ComissaoComposicaoEnumPipe,
    CandidaturaEstadoPipe,
    Composicao,
    Membro,
    SortAlphabeticallyPipe,
    VerificadorPipe,
    reciboPipe,
    IrsPipe,
    EstadoComissao,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    DottedListModule,
    MenuModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatChipsModule,
    RouterModule,
    MatTableModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatCardModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    NumeredListModule,
    AngularEditorModule,
    HttpClientModule,
    MatIconModule,
    MatMenuModule,
    CurrencyMaskModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DialogComponent,
    FontAwesomeModule,
    DottedListModule,
    ChangesHistoryModule,
    MenuModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    TimeMaskDirective,
    LoadingComponent,
    LoadingFullscreenComponent,
    MatChipsModule,
    BreadcrumbComponent,
    RouterModule,
    MatTableModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatCardModule,
    MatPaginatorModule,
    NumbersOnlyDirective,
    FormTemplateComponent,
    MatSlideToggleModule,
    MatAutocompleteModule,
    NifDirective,
    PhoneInputComponent,
    NoDataComponent,
    CollapseComponent,
    EstadoBolsa,
    AnosExperienciaProfissionalBolsa,
    MatTooltipModule,
    NumeredListModule,
    CollapseFaqComponent,
    AngularEditorModule,
    HttpClientModule,
    MatMenuModule,
    FormasAtribuicao,
    YearCalendarDirective,
    FasesConcursoHeader,
    FileUploadComponent,
    MoneyMaskDirective,
    EstadoCandidaturaPipe,
    AreaArtisticaPipe,
    DominioArtisticoPipe,
    NutsllPipe,
    MoneyPipe,
    EstadoVerificacaoCandidaturaPipe,
    VerificacaoCandidaturaConcursoPlanificacoesResponsaveisPipe,
    VerificacaoCandidaturaConcursoPlanificacaoDatafimPipe,
    EstadosBOtoFOBolsaConsultoresEspecialistasPipe,
    EstadoPercursoProfissionalPipe,
    TipoEquipaRHPipe,
    RelacaoContratualRHPipe,
    MatIconModule,
    TipoProgramaDeApoioPipe,
    EstadoEquipamentoEspacoPipe,
    EstadoEquipaPipe,
    OnlyLettersDirective,
    DateInputFormatDirective,
    Separador,
    CurrencyMaskModule,
    FilterSearchPipe,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    DateFieldsManagerComponent,
    ComissaoMembroEnumPipe,
    ComissaoComposicaoEnumPipe,
    CandidaturaEstadoPipe,
    Composicao,
    Membro,
    SortAlphabeticallyPipe,
    VerificadorPipe,
    reciboPipe,
    IrsPipe,
    EstadoComissao,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-PT' },
    { provide: MAT_DATE_FORMATS, useValue: PT_DATE_FORMATS },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true, strict: true },
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntlPT() }, // traducao do angular material paginator module
    // more providers defined in app.module.ts

    ComissaoMembroEnumPipe,
    ComissaoComposicaoEnumPipe,
    EstadoEquipamentoEspacoPipe,
    DatePipe,
    SortAlphabeticallyPipe,
    AppComponent,
    EstadoVerificacaoCandidaturaPipe,
    reciboPipe,
    IrsPipe,
    EstadoComissao,
  ],
})
export class SharedModule {}
