<div class="phone-input_wrapper" [formGroup]="iForm">
  <mat-form-field appearance="outline" class="phone-input_main">
    <label (click)="open()" matPrefix class="mx-3 fi fi-{{ this.selectedCode?.toLowerCase() }}"> </label>
    <input matInput type="tel" placeholder="Inserir" (input)="formatPhone()" formControlName="formattedPhone" id="phone-input_input" #phoneInputView [class.ng-dirty]="isPhoneInputDirty" required minlength="3" required maxlength="20" appNumbersOnly />

    <mat-select class="phone-input_country {{ openSelector ? 'is-active' : '' }}" #phoneInputCountry>
      <mat-option *ngFor="let country of countrySorted" (click)="setCodeNumber(country[0]); formatPhone()" [value]="country[0]">
        <div class="phone-input_flag-wrapper">
          <span class="fi mr-2 fi-{{ country[0].toLowerCase() }}"></span>
          {{ country[1] }}
        </div>
      </mat-option>
    </mat-select>

    <mat-error *ngIf="iForm.get('formattedPhone')?.hasError('pattern')"> Formato <strong>inválido</strong> </mat-error>

    <mat-error *ngIf="iForm.get('formattedPhone')?.hasError('required')"> Preenchimento <strong>obrigatório</strong> </mat-error>
  </mat-form-field>
</div>
