import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estadoComissao',
})
export class EstadoComissao implements PipeTransform {
  transform(value: number): string {
    const estadoComissao = ['cumpriu e superou o planeado', 'cumpriu como planeado', 'cumpriu ficando aquém do planeado', 'não cumpriu o planeado'];

    return estadoComissao[+value - 1];
  }
}
