import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'FormasAtribuicao'})
export class FormasAtribuicao implements PipeTransform {
  transform(value: number): string {
    const formasAtribuicao = [
      'Concurso', 
      'Concurso limitado', 
      'Procedimento simplificado', 
      'Renovação automática',
      'Outro'
    ]

    return formasAtribuicao[+value - 1];
  }
}
