import { Component } from '@angular/core';

@Component({
  selector: 'app-scroll-btn',
  templateUrl: './scroll-btn.component.html',
  styleUrls: ['./scroll-btn.component.scss']
})

export class ScrollBtn {
  hasScroll = false
  doc!: any;

  constructor(
  ){
    this.doc = window.document.documentElement;
    window.addEventListener('scroll', this.onScroll.bind(this))
  }

 scrollToTop(){
   this.doc.scrollTo(0,0);
 } 

 onScroll(){
   this.hasScroll = this.doc.scrollTop > 150
 }
}


