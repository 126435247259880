import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'verificacaoCandidaturaConcursoPlanificacaoDatafim'
})
export class VerificacaoCandidaturaConcursoPlanificacaoDatafimPipe implements PipeTransform {

  transform(value: any[]): string {
    let [planificacaoCandidatura] = value.filter(i => i.tipoPlanificacao === 1)

    if(!planificacaoCandidatura){
      return '';
    }
    return planificacaoCandidatura.dataFim;
  }
}
