import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appTimeMask]'
})
export class TimeMaskDirective {

  constructor() { }

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    let letters = value.split('')
    if( letters.length > 2){
      letters.splice(2,0,":")
    }

    if( letters.length > 5){
      letters.splice(5,0,":")
    }
    
    input.value = letters.slice(0,8).join('');
  }

}
