import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'src/app/core/layout/header/header.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContatosListComponent } from './contatos-list/contatos-list.component';
import { HeaderLinkComponent } from './header-link/header-link.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ContatosListComponent,
    HeaderLinkComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [
    HeaderComponent,
    ContatosListComponent,
    HeaderLinkComponent
  ]
})
export class HeaderModule { }
