import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';

interface ChildMenuListData {
  title: string;
  route: string[];
  disabled?: boolean;
}

interface clickedMenuItemEvent {
  clickedMenuTitle: string;
  clickedMenuPath: string;
}

export interface MenuListData {
  title: string;
  route: string[];
  child: ChildMenuListData[];
  disabled?: boolean;
  show?: boolean; // managed by animation
  routerActive?: boolean; // alternative way to visually indicate the current active menu without using routerLinks
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnChanges {
  @Input() menuList!: MenuListData[];
  previousParentClass!: string;
  currentPath!: string;

  // emits the path from the clicked menu
  @Output() switchedMenuSelection: EventEmitter<clickedMenuItemEvent | undefined> = new EventEmitter<clickedMenuItemEvent | undefined>();

  constructor(private router: Router) {}

  ngOnChanges() {
    this.currentPath = window.location.pathname;
    let parentClass!: string;

    let isDisabled!: boolean;

    this.menuList?.map(item => {
      if (this.currentPath === item?.route?.join('/')) {
        parentClass = item.title;
        isDisabled = !!item.disabled;
      }
    });

    this.openChildren(parentClass, isDisabled);

    setTimeout(() => {
      this.setMenuLinksAnimation();
    });
  }

  openChildren(parentClass: string, isDisabled: boolean) {
    if (isDisabled) return;

    if (this.previousParentClass) {
      let parent = document.querySelector(`.${this.previousParentClass.replaceAll(' ', '')} button`);
      parent?.classList.remove('hovered');

      let children = document.querySelectorAll(`.${this.previousParentClass.replaceAll(' ', '')}.child`);
      children?.forEach(child => {
        child.classList.add('d-none');
        child.classList.remove('d-block');
      });
    }

    this.previousParentClass = parentClass;

    let parent = document.querySelector(`.${parentClass?.replaceAll(' ', '')} button`);
    if (!parent?.classList.contains('no-children')) {
      // only if the element actually has child menus
      parent?.classList.toggle('hovered');

      let children = document.querySelectorAll(`.${parentClass?.replaceAll(' ', '')}.child`);
      children?.forEach(child => {
        child.classList.toggle('d-none');
        child.classList.toggle('d-block');
      });
    }
  }

  setMenuLinksAnimation(): void {
    if (this.menuList?.length > 0) {
      let lastButtonAnimationDelayMilisec = 0;
      Object.entries(this.menuList).forEach((entry: any) => {
        this.menuList[entry[0]].show = false;
        setTimeout(() => {
          this.menuList[entry[0]].show = true;
        }, lastButtonAnimationDelayMilisec);
        lastButtonAnimationDelayMilisec = lastButtonAnimationDelayMilisec + 80;
      });
    }
  }

  clickedMenuItem(menuItem: any): void {
    if (!menuItem?.disabled) {
      // ignore clicks if its disabled
      this.switchedMenuSelection.emit({
        clickedMenuTitle: menuItem?.title ?? '',
        clickedMenuPath: menuItem?.path ?? '',
      });
    }
  }
}
