import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'composicao'})
export class Composicao implements PipeTransform {
  transform(value: number): string {
  

    const estadosComposicao = [
      'COORDENADOR',
      'ESPECIALISTA',
      'TÉCNICO SUPERIOR DGARTES',      
    ]
    return estadosComposicao[+value -1];
  }
}
