import { ILinks } from 'src/app/shared/interfaces/link.interface';

export const individualLinks: Array<ILinks> = [
  {
    path: '/balcao-artes',
    title: 'Balcão artes',
    tooltip: 'Disponível brevemente',
    greyedOutBoolean: true,
  },
  {
    path: '/e-registo/dados-pessoais',
    title: 'E-registo',
  },
  {
    path: '/bolsa-consultores-especialistas',
    title: 'Bolsa de consultores e especialistas',
  },
  {
    path: '/programas-de-apoio/concursos-a-decorrer',
    title: 'Programas de apoio',
  },
  {
    path: '/rtcp',
    title: 'RTCP',
    // tooltip: 'Disponível brevemente',
    // greyedOutBoolean: true
  },
  {
    path: '/rpac',
    title: 'RPAC',
    // tooltip: 'Disponível brevemente',
    // greyedOutBoolean: true
  },
  {
    path: '',
    title: 'Consultas de dados',
    tooltip: 'Disponível brevemente',
    greyedOutBoolean: true,
  },
  {
    path: '',
    title: 'Serviços de Apoio',
    tooltip: 'Disponível brevemente',
    greyedOutBoolean: true,
  },
  {
    path: 'avaliacao-concursos',
    title: 'Avaliação de Concursos',
    tooltip: 'Disponível brevemente',
  },
];
